import {
  FormField,
  Label,
  Modal,
  NumberInput,
  TextArea,
  TextInput,
} from '@dynatrace/strato-components-preview';
import { Button, Flex } from '@dynatrace/strato-components';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { BusinessService } from '../../services/BusinessService';
import { useAppInfo } from '../../contexts/AppContext';

interface NewBusinessModalProps {
  onDismiss: (refresh: boolean) => void;
}

export const NewBusinessModal = (props: NewBusinessModalProps) => {
  const { onDismiss } = props;

  const [showModal, setShowModal] = useState(true);
  const { tenantId, userId } = useAppInfo();

  const {
    handleSubmit: submitNewBusiness,
    control: newBusinessControl,
    reset: newBusinessReset,
    formState: { isSubmitSuccessful: isNewBusinessSubmitSuccessful },
  } = useForm<{
    businessId: number;
    tenantId: number;
    userId: string;
    businessName: string;
    businessType: string;
    annualRevenue: number;
  }>({
    mode: undefined,
  });

  // save the information
  const saveBusiness = async (values: any) => {
    try {
      values.tenantId = tenantId;
      values.userId = userId;
      await BusinessService.createBusiness(values);
      ShowSuccessNotification('Business created successfully');
    } catch (error) {
      ShowErrorNotification('Error creating business', error);
    } finally {
      // save the information and then close
      onDismiss(true);
    }
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    submitNewBusiness(saveBusiness)(event);
  };

  return (
    <Modal
      show={showModal}
      title={'Add new business'}
      size={'small'}
      dismissible={false}
      footer={
        <>
          <Flex justifyContent='flex-end' gap={8}>
            <Button
              onClick={() => {
                setShowModal(false);
                onDismiss(true);
              }}
              variant='default'
            >
              Discard
            </Button>
            <Button
              form='new-business-form'
              type='submit'
              variant='accent'
              color='primary'
              disabled={isNewBusinessSubmitSuccessful}
            >
              Confirm
            </Button>
          </Flex>
        </>
      }
    >
      <form id='new-business-form' key={2} onSubmit={handleFormSubmit} onReset={() => newBusinessReset()} noValidate>
        <Flex flexDirection='column' width={512} minWidth={512} gap={16}>
          <Controller
            name='businessName'
            control={newBusinessControl}
            rules={{
              required: {
                value: true,
                message: 'Business name is required.',
              },
              maxLength: {
                value: 200,
                message: 'Please enter a valid business name.',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormField required>
                <Label>Business name</Label>
                <TextInput
                  placeholder='Enter business name'
                  controlState={{
                    state: error ? 'error' : 'valid',
                    hint: error?.message,
                  }}
                  {...field}
                />
              </FormField>
            )}
          />
          <Controller
            name='businessType'
            control={newBusinessControl}
            rules={{
              required: {
                value: true,
                message: 'Description is required',
              },
              minLength: {
                value: 10,
                message: 'Description cannot have less than 10 characters.',
              },
              maxLength: {
                value: 250,
                message: 'Description cannot have more than 250 characters.',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormField required>
                <Label>Business type</Label>
                <TextArea
                  width={'100%'}
                  controlState={{
                    state: error ? 'error' : 'valid',
                    hint: error?.message || 'Please enter between 10 and 250 characters.',
                  }}
                  {...field}
                />
              </FormField>
            )}
          />
          <Controller
            name='annualRevenue'
            control={newBusinessControl}
            rules={{
              required: {
                value: true,
                message: 'Please enter revenue value.',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormField required>
                <Label>Annual Revenue</Label>
                <NumberInput
                  aria-label='Annual-revenue'
                  placeholder='eg. 2000000'
                  controlState={{
                    state: error ? 'error' : 'valid',
                    hint: error?.message || 'Desired loan amount',
                  }}
                  {...field}
                />
              </FormField>
            )}
          />
        </Flex>
      </form>
    </Modal>
  );
};

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { LoadingStateComponent } from "./LoadingStateComponent";

export const AuthenticationGuard = ({ component }: { component: React.ComponentType }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <LoadingStateComponent loading={true}/>
    ),
  });

  return <Component />;
};
import { Navigate, Route, Routes } from 'react-router-dom';
import { Page, TitleBar, ToastContainer } from '@dynatrace/strato-components-preview';
import { Flex } from '@dynatrace/strato-components';
import { Sidebar } from './components/Sidebar';
import { AuthenticationGuard } from './components/AuthenticationGuard';
import { ProjectsPage } from './pages/projects/ProjectsPage';
import { BusinessesPage } from './pages/business/BusinessesPage';
import { LendersPage } from './pages/lenders/LendersPage';
import { PeoplesPage } from './pages/people/PeoplesPage';
import { EmptyStatePageComponent } from './components/EmptyStatePageComponent';
import { ProjectDetails } from './pages/projects/ProjectDetails';
import { Header } from './components/Header';
import { useAppInfo } from './contexts/AppContext';

export function AppRoutes() {
  const { isAppLoading } = useAppInfo();

  return (
    <Page>
      {/* Header */}
      <ToastContainer />
      {!isAppLoading && (
        <Page.Header>
          <Header />
        </Page.Header>
      )}
      {/* Sidebar */}
      {!isAppLoading && (
        <Page.Sidebar preferredWidth={50} minWidth={200}>
          <Flex flexDirection='row'>
            <TitleBar>
              <TitleBar.Title>Sidebar</TitleBar.Title>
            </TitleBar>
            <TitleBar.Suffix>
              <Page.PanelControlButton />
            </TitleBar.Suffix>
          </Flex>
          <Sidebar />
        </Page.Sidebar>
      )}
      {/* Main Content */}
      <Page.Main>
        <Routes>
          <Route path='/' element={<Navigate to='/projects' replace />} />
          <Route path='/projects' key={'p'} element={<AuthenticationGuard component={ProjectsPage} />} />
          <Route path='/businesses' element={<AuthenticationGuard component={BusinessesPage} />} />
          <Route path='/lenders' element={<AuthenticationGuard component={LendersPage} />} />
          <Route path='/people' element={<AuthenticationGuard component={PeoplesPage} />} />
          <Route path='projects/project/:ProjectId' element={<AuthenticationGuard component={ProjectDetails} />} />
          <Route path='*' element={<AuthenticationGuard component={EmptyStatePageComponent} />} />
        </Routes>
      </Page.Main>
    </Page>
  );
}

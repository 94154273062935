import { useState } from 'react';
import { NotesDetails } from '../../types/Notes';
import { NotesService } from '../../services/NotesService';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { Flex } from '@dynatrace/strato-components';
import { Avatar, Button, Surface, TextArea, Tooltip } from '@dynatrace/strato-components-preview';
import { DeleteIcon, EditIcon, SaveIcon } from '@dynatrace/strato-icons';
import { Colors } from '@dynatrace/strato-design-tokens';
import { Text } from '@dynatrace/strato-components-preview';
import { format, parseISO } from 'date-fns';
import { RequestDetails } from '../../types/Request';
import { ProjectService } from '../../services/ProjectService';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';

export type Document = {
  label: string;
  documentTypeName: string;
  assignedto: string;
  statusName: string;
  downloadFilePath: string;
  projectRequestSentId: number;
};

export type AssignedList = {
  assignedto: string;
  completed: number;
  inProgress: number;
  documents: Document[];
};

export type GroupedDocuments = {
  projectRequestId: number;
  projectRequestName: string;
  createdOn: string;
  assignedCount: number;
  assignedList: AssignedList[];
};

export const Notes = (
  newNotes: boolean,
  setNewNotes: React.Dispatch<React.SetStateAction<boolean>>,
  AddNewNotesClick: () => void,
  notes: NotesDetails[],
  refetch: any,
  addNewNotes: string,
  setAddNewNotes: React.Dispatch<React.SetStateAction<string>>,
  truncateNotesText: any,
) => {
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editDescription, setEditDescription] = useState('');
  const [loading, setloading] = useState(false);
  const handleEdit = async (projectNotes: NotesDetails, index: number) => {
    setEditIndex(index);
    setEditDescription(notes[index].notes);
  };

  const handleSave = async (projectNotes: NotesDetails, index: number) => {
    try {
      setloading(true);
      const updatedNotes = [...notes];
      updatedNotes[index].notes = editDescription;
      projectNotes.notes = editDescription;
      const data: any = await NotesService.updateStaffNotes<NotesDetails>(projectNotes);
      if (data) {
        ShowSuccessNotification('Note saved successfully');
        setEditIndex(null);
      }
      setloading(false);
    } catch (error) {
      ShowErrorNotification('Error occurred while editing the note', error);
      setloading(false);
    }
  };

  const handleNotesIndexCancel = () => {
    setEditIndex(null);
  };

  const handlenewNotesCancel = () => {
    setNewNotes(false);
    setAddNewNotes('');
  };

  const handleDelete = async (projectNotesId: any) => {
    try {
      setloading(true);
      await NotesService.deleteStaffNotes<NotesDetails>(projectNotesId);
      ShowSuccessNotification('Note deleted successfully');
      refetch();
      setloading(false);
    } catch (error) {
      ShowErrorNotification('Error occurred while deleting the note', error);
      setloading(false);
    }
  };

  return (
    <Flex flexDirection='column'>
      {loading && <LoadingStateComponent loading />}
      <Flex flexDirection='column' padding={0} gap={2}>
        <Flex flexDirection='row' alignItems='flex-end'>
          <Tooltip text={'Cancel Note'}>
            <Button onClick={handlenewNotesCancel} variant='default' color='neutral' disabled={addNewNotes === ''}>
              Discard
            </Button>
          </Tooltip>
          <Tooltip text={'Save Note'}>
            <Button
              onClick={AddNewNotesClick}
              variant='default'
              color='neutral'
              style={{ marginRight: '8px' }}
              disabled={addNewNotes === ''}
            >
              <Button.Prefix>
                <SaveIcon />
              </Button.Prefix>
              Save
            </Button>
          </Tooltip>
        </Flex>
        <TextArea
          width={'100%'}
          rows={5}
          placeholder={'Add a new note'}
          value={addNewNotes}
          onChange={(e) => setAddNewNotes(e)}
          style={{ marginBottom: '8px' }}
        />
      </Flex>
      <Surface style={{ overflowY: 'auto' }}>
        {notes?.map((person, index) => (
          <Surface key={index}>
            <Flex key={index} padding={2} flexDirection='column' gap={2}>
              <Flex flexDirection='row' justifyContent='space-between' alignItems='center'>
                <Flex flexDirection='row'>
                  <Avatar size={'default'} style={{}} abbreviation={person?.createdByName[0]?.toUpperCase()} />
                  <Flex flexDirection='column' gap={0}>
                    <Text style={{ fontSize: '14px' }} color={Colors.Text.Neutral.Default}>
                      <b>{person?.createdByName}</b>
                    </Text>
                    <Text color={Colors.Text.Neutral.Disabled}>
                      {person?.createdOn ? format(parseISO(person?.createdOn), 'MMM dd, yyyy HH:mm') : 'N/A'}
                    </Text>
                  </Flex>
                </Flex>
                {editIndex === index ? (
                  <Flex flexDirection='row' alignItems='flex-end'>
                    <Button
                      onClick={() => handleSave(person, index)}
                      variant='default'
                      color='neutral'
                      style={{ marginRight: '8px' }}
                    >
                      Save
                    </Button>
                    <Button onClick={handleNotesIndexCancel} variant='default' color='neutral'>
                      Cancel
                    </Button>
                  </Flex>
                ) : (
                  <Flex flexDirection='row'>
                    <Tooltip text={'Edit note'}>
                      <Button
                        onClick={() => handleEdit(person, index)}
                        variant='default'
                        color='neutral'
                        style={{ marginRight: '8px' }}
                      >
                        <Button.Prefix>
                          <EditIcon />
                        </Button.Prefix>
                      </Button>
                    </Tooltip>

                    <Tooltip text={'Delete Note'}>
                      <Button onClick={() => handleDelete(person.projectNotesId)} variant='default' color='neutral'>
                        <Button.Prefix>
                          <DeleteIcon />
                        </Button.Prefix>
                      </Button>
                    </Tooltip>
                  </Flex>
                )}
              </Flex>
              {editIndex === index ? (
                <TextArea
                  value={editDescription}
                  width={'100%'}
                  rows={5}
                  onChange={(e) => setEditDescription(e)}
                  style={{ marginTop: '8px' }}
                />
              ) : (
                <Flex flexDirection='column'>
                  <Text color={Colors.Text.Neutral.Default}>
                    {truncateNotesText(person.notes, index)}
                    <Text></Text>
                  </Text>
                </Flex>
              )}
            </Flex>
          </Surface>
        ))}
      </Surface>
    </Flex>
  );
};

export const downloadFileClick = function (rowInfo: any) {
  //In Local, uncomment the below line
  // window.open(ProjectService.getDownloadFileHeader(rowInfo.original.downloadFilePath));
  //In Dev, uncomment the below Line
  window.open(ProjectService.getDownloadFileHeader(rowInfo.downloadFilePath));
};

export const truncateText = (text: string, maxLines: number) => {
  const lines = text.split('. ');
  if (lines.length <= maxLines) {
    return text;
  }
  return lines.slice(0, maxLines).join('\n') + '...';
};

export const groupDocumentsByProjectRequestId = (projectRequests: RequestDetails[]) => {
  const grouped: { [key: number]: GroupedDocuments } = {};

  projectRequests.forEach((item) => {
    const {
      projectRequestId,
      projectRequestName,
      createdOn,
      label,
      documentTypeName,
      assignedto,
      statusName,
      downloadFilePath,
      projectRequestSentId,
    } = item;

    if (!grouped[projectRequestId]) {
      grouped[projectRequestId] = {
        projectRequestId,
        projectRequestName,
        assignedCount: 1,
        createdOn,
        assignedList: [],
      };
    }

    const assignedGroup = grouped[projectRequestId].assignedList.find((group) => group.assignedto === assignedto);

    if (assignedGroup) {
      assignedGroup.documents.push({
        label,
        documentTypeName,
        assignedto,
        statusName,
        downloadFilePath,
        projectRequestSentId,
      });
    } else {
      grouped[projectRequestId].assignedList.push({
        assignedto,
        completed: 0,
        inProgress: 0,
        documents: [
          { label, documentTypeName, assignedto, statusName, downloadFilePath, projectRequestSentId },
        ],
      });
    }
  });

  // Update documentsCount for each assigned group
  Object.values(grouped).forEach((project) => {
    const assignedCount = project.assignedList.length;
    project.assignedCount = assignedCount;
    project.assignedList.forEach((group) => {
      const inProgress = group.documents.filter(
        (doc) => doc.statusName !== 'Approved' && doc.statusName !== 'Rejected',
      ).length;
      group.inProgress = inProgress;
      const completed = group.documents.filter(
        (doc) => doc.statusName === 'Approved' || doc.statusName === 'Rejected',
      ).length;
      group.completed = completed;
    });
  });

  return Object.values(grouped);
};

import { Flex, } from '@dynatrace/strato-components';
import { EmptyStateComponent } from './EmptyStateComponent';
export const EmptyStatePageComponent = () => {
  // size: ['small', 'default', 'large']
  // context: ['chart', 'document', 'generic', 'query', 'table']
  // type: ['create-new','no-result','no-permission','something-missing','something-wrong',]
  return (
    <Flex flexDirection='row' justifyContent='center' width='100%' height='100%'>
      <EmptyStateComponent header="Page not found" message="" type="tab" />
      {/*<Flex flexDirection='column' justifyContent='center' alignItems='center'>
        <EmptyState height='100%'>Page not found</EmptyState>
      </Flex>
      }   <EmptyState.Visual>
          <EmptyState.VisualPreset context='generic' type='something-wrong' />
        </EmptyState.Visual>
        <EmptyState.Title>Page not found</EmptyState.Title>
        <EmptyState.Details>Some Details</EmptyState.Details>
        <EmptyState.Actions>
          <Button
            color="primary"
            variant="accent"
            onClick={() => console.log('Hello')}
          >
            Some Action
          </Button>
          <Button
            color="neutral"
            variant="default"
            onClick={() => console.log('World')}
          >
            Another Action
          </Button>
        </EmptyState.Actions>
        <EmptyState.Footer>
          Some text that suggests following an external link to the{' '}
          <ExternalLink href="www.dynatrace.com">
            Dynatrace Website
          </ExternalLink>
        </EmptyState.Footer>*/}
    </Flex>
  );
};

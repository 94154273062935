// src/services/apiService.ts
import axios, { AxiosError } from 'axios';
import { getAuthHeader, getBaseURL } from './AxiosAuthService';// Replace with your API endpoint

export const ProjectService = {
     createProject: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/project`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, updateProject: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.put(`${getBaseURL()}/project`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, getProject: async <T>(): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/project`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },  deleteProjectById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.delete(`${getBaseURL()}/project/${id}`);
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/project/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectDetailsById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/project/details/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getIndustries: async <T>(): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/master/getIndustrys`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getLoanTypes: async <T>(): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/master/getLoanTypes`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getStatues: async <T>(type: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/master/getProjectRequestTemplateStatus/${type}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectsByPeopleID: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/projectPeopleRelation/getProjectByPeople/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postProjectprojectWithDetails: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/project/projectWithDetails`,request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
   getProjectWithDetailsID: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/project/getProjectWithDetails/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postprojectPeopleRelationWithDetails: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/projectPeopleRelation`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postProjectBusinessesRelationWithDetails: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/projectBusinessesRelation`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    deleteProjectBusinessesRelationWithId: async <T>(bussinessPeopleId: any): Promise<T> => {
        try {
            const response: any = await axios.delete(`${getBaseURL()}/projectBusinessesRelation/${bussinessPeopleId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    deleteProjectPeopleRelationWithId: async <T>(peoplePeopleId: any): Promise<T> => {
        try {
            const response: any = await axios.delete(`${getBaseURL()}/projectPeopleRelation/${peoplePeopleId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectBusinessesRelationWithProjectId: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/projectBusinessesRelation/projectBusinesses/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, postprojectRequestWithDetails: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/project/createProjectRequest`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getProjectRequestById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/ProjectRequest/projectId?projectId=${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    updateProjectRequestStatus: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.put(`${getBaseURL()}/ProjectRequest/updateProjectRequestStatus`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getDownloadFileHeader: (request: any): string => {
        return `${getBaseURL()}/ProjectRequest/DownloadFileprojectRequestGet/${request}`;
    },
};




const handleApiError = (error: AxiosError) => {
    if (error.response) {
        console.error('API Error Response:', error.response.data);
    } else if (error.request) {
        console.error('API No Response:', error.request);
    } else {
        console.error('API Error:', error.message);
    }
};

import { ChangeEvent, useMemo, useRef, useState } from 'react';
import { DataTable, TableColumn, Avatar, Paragraph, Link, Strong } from '@dynatrace/strato-components-preview';
import { Button, Flex, Text } from '@dynatrace/strato-components';
import { Colors } from '@dynatrace/strato-design-tokens';
import { Surface } from '@dynatrace/strato-components-preview/layouts-core';
import { CriticalIcon, DeclineIcon, SuccessIcon, UploadIcon } from '@dynatrace/strato-icons';
import { UploadService } from '../../services/UploadService';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { format, parseISO } from 'date-fns';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { useProjectEmailDetails } from '../../hooks/use-minerva-data';

interface LandingPageProps {
  token: string;
}

interface DocumentUploadProps {
  label: string;
  createdOn: string;
  statusName: 'Sent' | 'Approved' | 'Rejected' | 'Received';
  remainder: string;
  updatedDate: string;
  attachments: string;
  documentTypeName: string;
  documentTypeDescription: string;
  documentTypeAutoId: string;
  projectRequestSentId: string;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>, details: any) => void;
  uploadedFiles: string[];
}

interface FileData {
  name: string;
  index: number;
  file: string;
}

type FileNameState = {
  [key: string]: FileData;
};
export const LandingPage = (props: LandingPageProps) => {
  const statusConfig = {
    Sent: {
      backgroundColor: Colors.Background.Container.Neutral.Emphasized,
      color: Colors.Text.Neutral.DefaultActive,
      icon: <UploadIcon />,
    },
    Approved: {
      backgroundColor: Colors.Background.Container.Success.Accent,
      color: Colors.Text.Neutral.OnAccent.Default,
      icon: <SuccessIcon />,
    },
    Acknowledged: {
      backgroundColor: Colors.Background.Container.Neutral.Accent,
      color: Colors.Text.Neutral.OnAccent.Default,
      icon: <CriticalIcon />,
    },
    Completed: {
      backgroundColor: Colors.Background.Container.Success.Emphasized,
      color: Colors.Text.Neutral.DefaultActive,
      icon: <SuccessIcon />,
    },
    Rejected: {
      backgroundColor: Colors.Background.Container.Critical.Accent,
      color: Colors.Text.Neutral.OnAccent.Default,
      icon: <DeclineIcon />,
    },
    Received: {
      backgroundColor: Colors.Background.Container.Warning.Accent,
      color: Colors.Text.Neutral.OnAccent.Default,
      icon: <SuccessIcon />,
    },
  };

  const [loading, setLoading] = useState<boolean>(false);
  const fileInputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [fileDataMap, setFileDataMap] = useState<{ [key: string]: { files: File[]; details: any } }>({});
  const [fileName, setFileName] = useState<FileNameState>({
    '0': { name: 'file1.pdf', index: 0, file: '' },
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const projectRequestDetails = useRef<any>();
  const documentData = useRef<DocumentUploadProps[]>([]);

  // get  request email details custom hook
  const {
    isLoading: projectEmailDetailsLoading,
    data: projectEmailDetailsData,
    error: projectEmailDetailsError,
    refetch: refetchProjectEmailDetails,
  } = useProjectEmailDetails(props.token);

  if (projectEmailDetailsError) {
    ShowErrorNotification('Error loading request templates', projectEmailDetailsError);
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, details: any) => {
    const files = event.target.files;
    if (files) {
      const fileURL = URL.createObjectURL(files[files.length - 1]);
      const fileArray = Array.from(files);
      const newFileName = fileArray[fileArray.length - 1].name; // Get the name of the last selected file

      // Update fileDataMap state
      setFileDataMap((prevMap) => ({
        ...prevMap,
        [details.documentTypeAutoId]: {
          files: [...(prevMap[details.documentTypeAutoId]?.files || []), ...fileArray],
          details,
        },
      }));

      // Update fileName state
      setFileName((prevFiles) => ({
        ...prevFiles,
        [details.projectRequestSentId]: {
          name: newFileName,
          file: fileURL,
        },
      }));
    }
    setIsSubmit(true);
  };

  if (!projectEmailDetailsLoading) {
    if (
      projectEmailDetailsData &&
      projectEmailDetailsData?.project &&
      projectEmailDetailsData?.projectRequestResponse &&
      projectEmailDetailsData.fileFormt
    ) {
      projectRequestDetails.current = projectEmailDetailsData;
      const transformedData: DocumentUploadProps[] = projectEmailDetailsData.projectRequestResponse.map(
        (item: any) => ({
          createdOn: item.createdDate,
          statusName: item.statusName,
          remainder: item.remindersDetails,
          updatedDate: item.updatedDate ? item.updatedDate : 'N/A',
          attachments: item.downloadFilePath ? [item.downloadFilePath] : ['N/A'],
          label: item.label,
          // documentTypeName: item.documentTypeName,
          documentTypeName: 'others',
          documentTypeDescription: item.documentTypeDescription,
          documentTypeAutoId: item.documentTypeAutoId,
          projectRequestSentId: item.projectRequestSentId,
          handleFileChange: handleFileChange,
          uploadedFiles: fileDataMap[item.documentTypeAutoId]?.files.map((file) => file.name) || [],
        }),
      );

      documentData.current = transformedData;
    } else {
      // setIsError(true);
    }
  }

  const columns: TableColumn[] = useMemo(
    () => [
      {
        id: 'createdOn',
        header: 'Created On',
        accessor: 'createdOn',
        columnType: 'date',
        width: 200,
        Cell: ({ value }: any) => {
          try {
            const date = parseISO(value);
            return format(date, 'MMM dd, yyyy HH:mm');
          } catch (error) {
            return 'Invalid date';
          }
        },
      },
      {
        id: 'statusName',
        header: 'Status',
        accessor: 'statusName',
        width: 100,
      },
      {
        id: 'updatedDate',
        header: 'Updated Date',
        accessor: 'updatedDate',
        columnType: 'date',
        width: 200,
        Cell: ({ value }: any) => {
          try {
            const date = parseISO(value);
            return format(date, 'MMM dd, yyyy HH:mm');
          } catch (error) {
            return 'N/A';
          }
        },
      },
      {
        id: 'attachments',
        header: 'Attachment',
        accessor: 'attachments',
        width: 200,
        Cell: (row: any) => {
          if (row.data[0].attachments !== 'N/A') {
            return <Text>{String(row?.data[0]?.attachments).split(row.data[0].projectRequestSentId + '/')[1]}</Text>;
          }

          return <Text>{row.data[0].attachments}</Text>;
        },
      },
    ],
    [],
  );

  const blobToData = async (
    file: File,
    index: any,
    projectRequest: any,
    tenantId: any,
    projectId: any,
    peopelId: any,
    fileFormt: any,
    token: any,
    details: any,
  ) => {
    const fileDataUrl = await getFileDataUrl(file);
    const base64Data = fileDataUrl.split(',')[1];
    // const mimeTypeSegment = fileDataUrl.split(',')[0];
    // const mimeType = mimeTypeSegment.split(':')[1].split(';')[0];
    const fileext = file.name.split('.')[1];
    // const fileext = fileFormt[0]?.fileTypeName;

    const params = {
      token,
      tenantId,
      projectId,
      projectRequestId: projectRequest?.projectRequestId,
      projectRequestSentId: details.projectRequestSentId,
      peopelId,
      documentTypeAutoId: details.documentTypeAutoId,
      fileData: base64Data,
      fileext: '.' + fileext,
      fileName: `${file.name.split('.')[0]}`,
    };

    await UploadService.postProjectRequestUploadFiles(params);
  };

  const getFileDataUrl = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result) {
          resolve(reader.result as string);
        } else {
          reject(new Error('Failed to read file'));
        }
      };
      reader.onerror = () => reject(new Error('Error reading file'));
      reader.readAsDataURL(file);
    });
  };

  const handleSave = async () => {
    if (!projectRequestDetails || Object.keys(fileDataMap).length === 0) {
      ShowErrorNotification('Please select a file to upload');
      return;
    }

    try {
      setLoading(true);
      const { fileFormt, projectRequestResponse, projectRequest } = projectRequestDetails.current;
      const token = props.token;
      const tenantId = fileFormt[0]?.tenantId;
      const projectId = projectRequest.projectId;
      const peopelId = projectRequestResponse[0].peopleId;

      let apiCalls: Promise<void>[] = [];
      Object.keys(fileDataMap).forEach((documentTypeAutoId) => {
        const { files, details } = fileDataMap[documentTypeAutoId];
        files.forEach((file, index) => {
          if (index === files.length - 1) {
            apiCalls.push(
              blobToData(file, index, projectRequest, tenantId, projectId, peopelId, fileFormt, token, details),
            );
          }
        });
      });

      const data = await Promise.all(apiCalls);
      if (data) {
        ShowSuccessNotification('Files uploaded successfully');
        refetchProjectEmailDetails();
      }
    } catch (error) {
      ShowErrorNotification('Error uploading files', error);
    } finally {
      setLoading(false);
      setIsSubmit(false);
    }
  };

  // Define the callback ref function
  const setInputRef = (index: number, element: HTMLInputElement | null) => {
    if (element) {
      element.onclick = () => handleClick(index); // Add the onClick event handler
    }
    fileInputRefs.current[index] = element;
  };

  // Define the click event handler
  const handleClick = (index: number) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index]!.click();
    }
  };

  return (
    <Flex flexDirection='column' gap={32} height={600}>
      {loading && <LoadingStateComponent loading={loading} />}
      <Flex flexDirection='column' gap={4}>
        {/* Header Section */}
        <header
          className='header'
          style={{
            backgroundColor: Colors.Background.Container.Primary.Default,
            padding: '10px 20px',
            position: 'relative',
          }}
        >
          <Flex
            flexDirection='row'
            justifyContent='space-between'
            alignItems='center'
            width='100%'
            className='header-container'
          >
            {/* Left Section: Minerva and Tagline */}
            <Flex flexDirection='column' alignItems='flex-start' columnGap={0}>
              <Text as='h2'>Minerva</Text>
              <Text as='h6'>Small business funding made easy!</Text>
            </Flex>

            {/* Center Section: Request Name */}
            {projectRequestDetails?.current?.projectRequest?.projectRequestName && (
              <Text as='h3' style={{ textAlign: 'center' }}>
                <strong>{projectRequestDetails?.current?.projectRequest?.projectRequestName}</strong>
              </Text>
            )}

            {/* Right Section: Help Button */}
            <Button variant='default'>Help?</Button>
          </Flex>
        </header>

        {/* User Info Section */}
        <Flex flexDirection='column' className='userInfo' title={''}>
          <h4>
            {projectRequestDetails?.current?.people?.lastName +
              ', ' +
              projectRequestDetails?.current?.people?.firstName}
          </h4>
          <Flex style={{ backgroundColor: Colors.Background.Container.Primary.Default, padding: '5px' }}>
            <Text>
              <Avatar abbreviation='JS' />
            </Text>
            <Flex flexDirection='column'>
              <Text style={{ marginLeft: '24px' }}>
                Hi, I'm Jason, and I'll be working with you to ensure that your loan process for "
                <Strong>{projectRequestDetails?.current?.projectRequest?.projectRequestName}</Strong>" goes smoothly.
                The first step in securing funding is assessing your 'lendability.' We do this by collecting critical
                financial information, analyzing it, and producing a Lendability Report. Let's get started.
              </Text>
              <Paragraph>
                We know filling out documents and uploading information can seem daunting, so we want to make this
                process as easy as possible for you. Think of this link as your to-do list for "
                <Strong>{projectRequestDetails?.current?.projectRequest?.projectRequestDescription}</Strong>". Review
                the list of items we need, and start uploading them. If some documents are easier for you to obtain,
                feel free to upload those first and continue working on the others. Whenever you're ready to upload new
                documents, simply return to this link, click the corresponding upload button, and submit your documents.
              </Paragraph>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex flexDirection='column' gap={8}>
        <Flex flexDirection='column' gap={8} height={320} width={'98%'}>
          {documentData.current.map((doc, index) => (
            <Flex flexDirection='column' gap={4}>
              <Surface elevation='raised' key={index} className='documentList' title={''}>
                <h3>{doc.label + ' - ' + doc.documentTypeName}</h3>
                <Flex flexDirection='row' rowGap={8}>
                  <DataTable data={[doc]} variant={{ contained: false }} columns={columns} style={{ width: '90%' }} />
                  {doc.statusName !== 'Sent' && doc.statusName !== 'Rejected' ? (
                    <Button
                      style={{
                        backgroundColor: statusConfig[doc.statusName].backgroundColor,
                        color: statusConfig[doc.statusName].color,
                        height: 'auto',
                        width: '11%',
                      }}
                    >
                      <Button.Prefix>{statusConfig[doc.statusName].icon}</Button.Prefix>
                      <Text>{doc.statusName}</Text>
                    </Button>
                  ) : (
                    <>
                      <input
                        type='file'
                        ref={(el) => setInputRef(index, el)}
                        style={{ display: 'none' }}
                        onChange={(event) => handleFileChange(event, doc)}
                      />
                      <Button
                        style={{
                          height: 'auto',
                          width: '10%',
                          backgroundColor: statusConfig[doc.statusName].backgroundColor,
                          color: statusConfig[doc.statusName].color,
                        }}
                        onClick={() => handleClick(index)}
                      >
                        <Button.Prefix>{statusConfig[doc.statusName].icon}</Button.Prefix>
                        {doc.statusName === 'Rejected' ? <Text>Let's try again!</Text> : <Text>Upload</Text>}
                      </Button>

                      {/* {fileName} */}
                      {/* <Button variant="accent" onClick={clickInput}>
                            Upload
                          </Button> */}
                    </>
                  )}
                </Flex>
                <Flex flexDirection='row' justifyContent='flex-end'>
                  {fileName?.[doc.projectRequestSentId]?.name && (
                    <Link
                      href={fileName[doc.projectRequestSentId].file}
                      target='_blank' // This will open the file in a new tab
                      download={fileName[doc.projectRequestSentId].name}
                    >
                      {fileName?.[doc.projectRequestSentId]?.name}
                    </Link>
                  )}
                </Flex>
              </Surface>
            </Flex>
          ))}
          <Flex flexDirection='row' justifyContent='flex-end' marginTop='auto'>
            <Button variant='accent' className='submitButton' onClick={handleSave} disabled={!isSubmit}>
              Submit
            </Button>
          </Flex>
          <Flex>&nbsp;</Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default LandingPage;

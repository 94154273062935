import axios, { AxiosError } from 'axios';
import { getAuthHeader, getBaseURL } from './AxiosAuthService'; // Replace with your API endpoint

export const LenderService = {
    getState: async <T>(endpoint: string): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/states`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, createLenders: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/lender`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, updateLender: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.put(`${getBaseURL()}/lender`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, getLenders: async <T>(): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/lender`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, getBusinessDetailsById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/peopleBusinessRelation/clientsRelation/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, deleteLendersById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.delete(`${getBaseURL()}/lender/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getLenderById: async <T>(id: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/lender/${id}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getBusinessesDetailsByTenantId: async <T>(tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/tenant/businessesByTenant/${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    getPeopleByTenantId: async <T>(tenantId: number): Promise<T> => {
        try {
            const response: any = await axios.get(`${getBaseURL()}/tenant/peoplesByTenant/${tenantId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }
};

const handleApiError = (error: AxiosError) => {
    if (error.response) {
        console.error('API Error Response:', error.response.data);
    } else if (error.request) {
        console.error('API No Response:', error.request);
    } else {
        console.error('API Error:', error.message);
    }
};

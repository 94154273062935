import { Modal } from '@dynatrace/strato-components-preview';
import { Flex, Button } from '@dynatrace/strato-components';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { WarningIcon } from '@dynatrace/strato-icons';

export const NotAuthorizedModal = () => {
  const { logout } = useAuth0();
  const [,setShowModal] = useState(true);

  const handleCancel = () => {
    setShowModal(false);
    logout();
  };

  return (
    <Modal
      show={true}
      title={`Access denied`}
      size={'small'}
      dismissible={false}
      footer={
        <>
          <Flex justifyContent='center'>
            <Button width='100px' onClick={handleCancel} variant='accent'>
              OK
            </Button>
          </Flex>
        </>
      }
    >
      <WarningIcon style={{ verticalAlign: 'middle' }} />
      Looks like you have proper login credentials to access Minerva application, but you have not been assigned a
      proper role to access application features.
      <br />
      <br />
      Please contact your administrator to get required permissions.
    </Modal>
  );
};

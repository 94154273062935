import { getMinervaUserTemplate } from '../types/AppInfo';
import { ThemeType, UserLoginStatus } from '../types/Types';
import { MinervaUser } from '../types/UserInfo';

export const delay = (ms: number, ignore: boolean) => new Promise((resolve) => setTimeout(resolve, ignore ? 0 : ms));

export const setLocalMinervaBrowserTheme = (selectedTheme: string) => {
  if (selectedTheme === 'dark' || selectedTheme === 'light') {
    document.documentElement.setAttribute('data-theme', selectedTheme);
  } else {
    const themeQuery = window && window.matchMedia('(prefers-color-scheme: dark)');
    document.documentElement.setAttribute('data-theme', themeQuery.matches ? 'dark' : 'light');
  }
};
export function getMinervaBrowserTheme(): ThemeType {
  const minervaUser = getMinervaUser();
  return minervaUser.theme;
}

export function getMinervaUser(): MinervaUser {
  return JSON.parse(localStorage.getItem(UserLoginStatus) ?? JSON.stringify(getMinervaUserTemplate())) as MinervaUser;
}

export const updateMinervaUser = (minervaUser: MinervaUser) => {
  localStorage.setItem(UserLoginStatus, JSON.stringify(minervaUser));
};

export const updateMinervaBrowserTheme = (selectedTheme: ThemeType) => {
  const minervaUser = getMinervaUser();
  minervaUser.theme = selectedTheme;
  updateMinervaUser(minervaUser);
};

export const updateMinervaUserToken = (userToken: string | undefined) => {
  const minervaUser = getMinervaUser();
  minervaUser.userToken = userToken ?? '';
  updateMinervaUser(minervaUser);
};

export function getMinervaUserToken() {
  return getMinervaUser().userToken ?? '';
}

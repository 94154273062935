export type ThemeType = 'browser' | 'dark' | 'light';

export const UserLoginStatus: string = 'minervaUserLoginInfo';

export const PARENT_TYPE_PROJECT = 'project';
export const PARENT_TYPE_BUSINESS = 'business';
export const PARENT_TYPE_LENDER = 'lender';
export const PARENT_TYPE_PERSON = 'person';
export const PARENT_TYPE_NONE = 'none';

export type ModalParentType = 'project' | 'business' | 'lender' | 'person' | 'none';

import React, { useMemo, useRef, useState } from 'react';
import {
  Modal,
  TextInput,
  TextArea,
  SelectV2,
  FormField,
  Label,
  FormFieldMessages,
  Tabs,
  Tab,
  DataTable,
  Sheet,
  Tooltip,
  TitleBar,
  uuidv4,
} from '@dynatrace/strato-components-preview';
import { Button, Flex, Text } from '@dynatrace/strato-components';
import { Colors, Borders } from '@dynatrace/strato-design-tokens';
import { Controller, useForm, useWatch, useFieldArray } from 'react-hook-form';
import { DeleteIcon, RequestIcon, MailIcon, SourceIcon, CriticalIcon } from '@dynatrace/strato-icons';
import { ProjectService } from '../../services/ProjectService';
import { ProjectDetail, ProjectPeopleRelation } from '../../types/Project';
import { RequestDetails } from '../../types/Request';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { RequestTemplate, RequestTemplateDetails } from '../../types/RequestTemplate';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { NewProjectPeopleRelationModal } from './NewProjectPeopleRelationModal';
import { PARENT_TYPE_PROJECT } from '../../types/Types';
import { ItemInfo } from '../../types/ListItemInfo';
import { useAppInfo } from '../../contexts/AppContext';
import { useDocumentTypeList, useReminderList, useRequestTemplates } from '../../hooks/use-minerva-data';
import { TemplateService } from '../../services/TemplateService';
import styled from 'styled-components';

interface IDocument {
  label: string;
  documentTypeAutoId: number;
}

interface ProjectsLinkToPeopleModel {
  isOpen: boolean;
  onClose: () => void;
  peopleList: ProjectPeopleRelation[];
  projectDetails: ProjectDetail | any;
  onRequestCreated: (newRequest: RequestDetails) => void;
}
interface ReminderDetails {
  remindersAutoId: number;
  remindersId: number;
  tenantId: number;
  details: string;
}

const VerticalLine = styled.div`
  border-left: 2px solid ${Colors.Border.Neutral.Default};
  margin: 0 8px;
`;

const CreateRequest: React.FC<ProjectsLinkToPeopleModel> = ({
  isOpen,
  onClose,
  peopleList,
  projectDetails,
  onRequestCreated,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const initialDocumentTypeAutoId = -1;
  const [selectedUsers, setSelectedUsers] = useState<any[]>();
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});
  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const [showExistingPersonModal, setShowExistingPersonModal] = useState<boolean>(false);
  const [addPersonsVisible, setAddPersonsVisible] = useState<ItemInfo | undefined>();
  const [sendConfirmModal, setSendConfirmModal] = useState<boolean>(false);
  const [receipents, setReceipents] = useState<ProjectPeopleRelation[]>(peopleList);
  const { tenantId } = useAppInfo();
  // use refs for hooks
  const documentTypes = useRef<RequestTemplateDetails[]>([] as RequestTemplateDetails[]);
  const requestTemplateData = useRef<RequestTemplate[]>([] as RequestTemplate[]);
  const reminderList = useRef<ReminderDetails[]>([] as ReminderDetails[]);
  const [activeTab, setActiveTab] = useState('Request');
  if (receipents.length === 0 && peopleList.length > 0) {
    setReceipents([...peopleList]);
  }

  // get document types list custom hook
  const {
    isLoading: documentTypesLoading,
    data: documentTypesData,
    error: documentTypesError,
    // refetch: refetchDocumentTypes,
  } = useDocumentTypeList(Number(tenantId));

  if (documentTypesError) {
    ShowErrorNotification('Error loading documnent types list', documentTypesError);
  }

  // get  templates custom hook
  const {
    isLoading: requestTemplatesLoading,
    data: requestTemplatesData,
    error: requestTemplatesError,
    refetch: refetchRequestTemplates,
  } = useRequestTemplates();

  if (requestTemplatesError) {
    ShowErrorNotification('Error loading request templates', requestTemplatesError);
  }

  // get document types custom hook
  const {
    isLoading: reminderListLoading,
    data: reminderListData,
    error: reminderListError,
    // refetch: refetchReminderList,
  } = useReminderList(Number(tenantId));

  if (reminderListError) {
    ShowErrorNotification('Error loading reminders list', reminderListError);
  }

  if (!documentTypesLoading && !requestTemplatesLoading && !reminderListLoading) {
    documentTypes.current = documentTypesData.documentTypes;

    const tenantTemplates = requestTemplatesData?.filter(
      (template: { tenantId: number }) => template.tenantId === tenantId,
    );

    if (tenantTemplates !== undefined) {
      requestTemplateData.current = tenantTemplates;
    }

    reminderList.current = reminderListData;
  }

  const {
    handleSubmit,
    control,
    reset,

    formState: { errors },
  } = useForm<{
    template: string;
    requestName: string;
    requestDescription: string;
    requestDetails: Array<IDocument>;
  }>({
    reValidateMode: 'onChange',
    defaultValues: {
      requestDetails: [
        {
          label: '',
          documentTypeAutoId: initialDocumentTypeAutoId,
        },
      ],
    },
    mode: undefined,
  });

  let createRequestErrors = errors;
  const formValues = useWatch({
    control,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'requestDetails',
  });

  const [formData, setFormData] = useState({
    template: 0,
    requestName: '',
    requestDescription: '',
    requestDetails: [
      {
        requestTemplateDetailsId: 0,
        requestTemplateId: 0,
        tenantId: 0,
        label: '',
        documentTypeAutoId: initialDocumentTypeAutoId,
        documentTypeName: '',
      },
    ],

    reminder: '',
    recipients: [
      {
        email: '',
        firstName: '',
        lastName: '',
        peopleId: '',
      },
    ],
  });

  const addPerson = () => {
    setAddPersonsVisible({
      id: parseInt(projectDetails?.project?.projectId),
      name: projectDetails?.project.projectName,
      visibility: true,
    });
  };

  // updates project people relations post creating a new one or deleting
  const updateProjectPeopleRelations = async (addPersonsVisible: ItemInfo) => {
    try {
      setLoading(true);
      const data = await ProjectService.getProjectWithDetailsID<ProjectDetail>(addPersonsVisible?.id ?? 0);
      setReceipents(data.projectPeopleRelation);
    } catch (error) {
      ShowErrorNotification('Error updating project people relation', error);
    } finally {
      // setLoadingPeopleRelations(false);
      setLoading(false);
    }
  };

  const handleTemplateChange = async (value: any) => {
    try {
      const template = requestTemplateData.current.find((temp: any) => temp.requestTemplateId === value);
      if (template) {
        const { requestTemplateName, requestTemplateDescription, requestTemplateDetails } = template;

        reset({
          template: value,
          requestName: requestTemplateName,
          requestDescription: requestTemplateDescription,
          requestDetails: requestTemplateDetails.map((detail: any) => ({
            label: detail.label,
            documentTypeAutoId: detail.documentTypeAutoId,
          })),
        });
      }
    } catch (error) {
      ShowErrorNotification('Error loading template data', error);
    }
  };

  const handleRecipientsChange = (
    selectedRows: Record<string, boolean>,
    selectedRowsData: any[],
    trigger: 'user' | 'internal',
  ) => {
    setSelectedRows(selectedRows);
    // Map the selected indices to the actual row data
    const updatedRecipients = selectedRowsData.map((row) => ({
      email: row.email,
      firstName: row.firstName,
      lastName: row.lastName,
      peopleId: row.peopleId,
    }));
    setSelectedUsers(selectedRowsData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      recipients: updatedRecipients,
    }));
    setValidationErrors({});
  };

  const validateCreateRequestTab = (values: any) => {
    const errors: { [key: string]: string } = {};
    if (!values.requestName) {
      errors['Request description'] = 'Request description is required';
    }
    if (!values.requestDescription) {
      errors['Request message'] = 'Request message is required';
    }//
    values.requestDetails.map((document: any) => {
      if (!document.label) {
        errors['Document Name'] = 'File name is required';
      }
      if (!document.documentTypeAutoId) {
        errors['Document Type'] = 'File type is required';
      }
      return null;
    });

    return errors;
  };

  const validateAddRecipientsTab = () => {
    const errors: { [key: string]: string } = {};
    if (formData?.recipients.length === 0 || formData?.recipients[0].email === '') {
      errors['recipients'] = 'Atleast one recipient must be selected';
    }

    return errors;
  };

  const [tabOptions, setTabOptions] = useState({
    'Request': false,
    'Recipients': true,
    'Request overview': true,
  });

  const handleCreateRequestNextButtonClick = (values: any) => {
    if (activeTab === 'Request') {
      // saveDetails(data);
      const errors = validateCreateRequestTab(values);
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
        enableNextTab();
      } else {
        setValidationErrors(errors);
      }
    }
  };
  const handleNextButtonClick = () => {
    if (activeTab === 'Recipients') {
      const errors = validateAddRecipientsTab();
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
        enableNextTab();
      } else {
        setValidationErrors(errors);
      }
    }
  };

  const enableNextTab = () => {
    const nextIndex = (currentTabIndex + 1) % tabs.length;
    setTabOptions((prevOptions) => ({
      ...prevOptions,
      [tabs[nextIndex]]: false,
    }));
    setCurrentTabIndex(nextIndex);
    setActiveTab(tabs[nextIndex]);
  };
  const disablePrevTab = () => {
    // const nextIndex = currentTabIndex % tabs.length;
    const prevIndex = (currentTabIndex - 1) % tabs.length;
    setCurrentTabIndex(prevIndex);
    setActiveTab(tabs[prevIndex]);
  };
  // const [reminderList, setReminderList] = useState<ReminderDetails[]>([]);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const tabs = ['Request', 'Recipients', 'Request overview'];

  const ReceipentColumns = useMemo(
    () => [
      {
        id: 'firstName',
        header: 'First name',
        accessor: 'firstName',
        width: 180,
      },
      {
        id: 'lastName',
        header: 'Last name',
        accessor: 'lastName',
        width: 200,
      },
      {
        id: 'email',
        header: 'Email Id',
        accessor: 'email',
      },
    ],
    [],
  );

  const handleAddMore = () => {
    append({
      label: '',
      documentTypeAutoId: initialDocumentTypeAutoId,
    });
  };

  const getDocumentTypeName = (documentTypeAutoId: any) => {
    return documentTypes.current.find((docType) => docType.documentTypeAutoId === documentTypeAutoId)?.documentTypeName;
  };

  // save the information
  const saveDetails = async (values: any) => {
    const { template, requestName, requestDescription, requestDetails } = values;

    setFormData((prevData) => ({
      ...prevData,
      template: template || prevData.template,
      requestName: requestName || prevData.requestName,
      requestDescription: requestDescription || prevData.requestDescription,
      requestDetails: requestDetails.map((doc: IDocument, index: number) => ({
        ...prevData.requestDetails[index],
        label: doc.label,
        documentTypeAutoId: doc.documentTypeAutoId,
      })),
    }));
    handleCreateRequestNextButtonClick(values);
  };

  const handleDismiss = () => {
    // Resetting additional form data state if needed
    setFormData({
      template: 0,
      requestName: '',
      requestDescription: '',
      requestDetails: [
        {
          requestTemplateDetailsId: 0,
          requestTemplateId: 0,
          tenantId: 0,
          label: '',
          documentTypeAutoId: initialDocumentTypeAutoId,
          documentTypeName: '',
        },
      ],
      reminder: '0',
      recipients: [
        {
          email: '',
          firstName: '',
          lastName: '',
          peopleId: '',
        },
      ],
    });
    reset({
      template: '',
      requestName: '',
      requestDescription: '',
      requestDetails: [
        {
          label: '',
          documentTypeAutoId: initialDocumentTypeAutoId,
        },
      ],
    });
    setSelectedRows({});
    // Reset the active tab to the first tab
    setCurrentTabIndex(0);
    setActiveTab('Request');
    // Optionally, reset tab options if needed
    setTabOptions({
      'Request': false,
      'Recipients': true,
      'Request overview': true,
    });

    onClose();
  };

  // Function to handle tab change
  const handleTabChange = (index: number) => {
    // Implement any logic needed before switching tabs, such as validation
    const isValid = true;
    if (isValid) {
      setCurrentTabIndex(index);
      setTabOptions((prevOptions) => ({
        ...prevOptions,
        [Object.keys(tabOptions)[index]]: false,
      }));
      const tabKeys = Object.keys(tabOptions);
      const tabKeyToIndex = tabKeys.reduce((acc, key, index) => {
        acc[index] = key;
        return acc;
      }, {} as { [key: number]: string });
      setActiveTab(tabKeyToIndex[index]);
    } else {
      // Handle case when validation fails
      // console.warn('Validation failed, cannot change tab.');
    }
  };

  const handleSaveTemplate = handleSubmit(async (values) => {
    const { requestName, requestDescription, requestDetails } = values;

    let formInit = {
      requestTemplateId: 0,
      requestTemplateName: '',
      requestTemplateDescription: '',
      remindersAutoId: '',
      requestTemplateDetails: [
        {
          documentTypeAutoId: 1,
          label: '',
        },
      ],
    };
    // let isValid = true;
    for (let i = 0; i < requestDetails.length; i++) {
      if (!requestDetails[i].label || requestDetails[i].label.trim() === '') {
        // isValid = false;
        // ShowErrorNotification('Invalid Document Types');
        return;
      } else if (requestDetails[i].documentTypeAutoId === 1) {
        requestDetails[i].documentTypeAutoId = documentTypes.current[0].documentTypeAutoId;
      }
    }
    formInit.requestTemplateName = requestName;
    formInit.requestTemplateDescription = requestDescription;
    formInit.remindersAutoId = reminderList.current[0].remindersAutoId.toString();
    formInit.requestTemplateDetails = requestDetails;

    try {
      const errors = validateCreateRequestTab(values);
      if (Object.keys(errors).length === 0) {
        setValidationErrors({});
      } else {
        setValidationErrors(errors);
      }
      // handleCreateRequestNextButtonClick(values);
      if (Object.keys(errors).length === 0) {
        try {
          setLoading(true);

          const data: any = await TemplateService.postRequestTemplates(formInit);
          if (data) {
          }
          ShowSuccessNotification(values.requestName + ' successfully Saved.');
          refetchRequestTemplates();

          // onTemplateClose(tenantId);
        } catch (error) {
          ShowErrorNotification('Error creating template', error);
        }
      }
    } catch (error) {
      ShowErrorNotification('Error creating template', error);
    } finally {
      setLoading(false);
    }
  });
  const handleSend = handleSubmit((values) => {
    // Perform validations
    const CreateRequestErrors = validateCreateRequestTab(values);
    const ReceipentsErrors = validateAddRecipientsTab();

    // Merge both errors
    const combinedErrors = { ...CreateRequestErrors, ...ReceipentsErrors };

    // If there are any errors, set them in validationErrors
    if (Object.keys(combinedErrors).length !== 0) {
      setValidationErrors(combinedErrors);
    } else {
      // If there are no errors, clear the validationErrors
      setValidationErrors({});
      // Show the send confirmation modal
      setSendConfirmModal(true);
    }
  });

  // Function to handle the Yes click
  const handleYesClick = () => {
    CreateRequestSubmitClick(formData, projectDetails, documentTypes);
    setSendConfirmModal(false);
  };

  // Function to close the modal when No is clicked
  const handleNoClick = () => {
    setSendConfirmModal(false);
  };

  const CreateRequestSubmitClick = async (formData: any, projectDetails: any, documentTypes: any) => {
    const { requestDetails } = formData;
    let mapProjectRequest: any = {
      requestName: '',
      requestDescription: '',
      reminderId: '',
      requestDetails: [],
      document: [],
      requestSendTo: [],
      tenentId: 0,
      projectId: 0,
    };
    mapProjectRequest.requestName = formData.requestName;
    mapProjectRequest.requestDescription = formData.requestDescription;
    mapProjectRequest.requestDetails = [];
    mapProjectRequest.requestSendTo = [];
    mapProjectRequest.projectId = projectDetails?.project.projectId;
    mapProjectRequest.tenentId = projectDetails.project.tenantId;
    mapProjectRequest.statusAutoId = 10;
    mapProjectRequest.reminderId = reminderList.current[0].remindersAutoId;
    requestDetails.forEach((reqDetails: any) => {
      mapProjectRequest.requestDetails.push({
        label: reqDetails.label,
        documentTypeAutoId:
          reqDetails.documentTypeAutoId === 1 ? documentTypes[0].documentTypeAutoId : reqDetails.documentTypeAutoId,
      });
      selectedUsers?.forEach((user: any) => {
        mapProjectRequest.requestSendTo.push({
          sendTo: user.email,
          sendCC: '',
          status: 10,
          label: reqDetails.label,
          peopleId: user.peopleId,
          documentTypeAutoId:
            reqDetails.documentTypeAutoId === 1 ? documentTypes[0].documentTypeAutoId : reqDetails.documentTypeAutoId,
        });
      });
    });

    try {
      setLoading(true);
      await ProjectService.postprojectRequestWithDetails<Request>(mapProjectRequest);
      ShowSuccessNotification('Request created successfully');
      onRequestCreated(mapProjectRequest);
      handleDismiss();
    } catch (error) {
      ShowErrorNotification('Error while creating Request', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Sheet show={isOpen} onDismiss={handleDismiss}>
      <Modal title='Send request confirmation' show={sendConfirmModal} onDismiss={handleNoClick} size={'small'}>
        <Flex flexDirection='column' columnGap={4}>
          <Text>You are about to send this request to all recipients.</Text>
          <Flex flexDirection='row' justifyContent='flex-end' rowGap={4}>
            <Button width='80px' variant='default' onClick={handleNoClick}>
              Cancel
            </Button>
            <Button width='80px' variant='accent' color='primary' onClick={handleYesClick}>
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Modal>
      <LoadingStateComponent loading={loading} />
      <form onSubmit={handleSubmit(saveDetails)} onReset={() => reset()} noValidate>
        <TitleBar>
          <TitleBar.Title> {'Create New Request'} </TitleBar.Title>
          <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>
            <Flex justifyContent='flex-end' gap={8}>
              <Button
                width='200px'
                variant='emphasized'
                color='primary'
                onClick={() => {
                  handleSaveTemplate();
                }}
                type='button'
              >
                Save request as template
              </Button>
              <VerticalLine />
              <Button width='80px' variant='default' onClick={handleDismiss}>
                Cancel
              </Button>

              <Button width='80px' variant='emphasized' onClick={disablePrevTab} disabled={activeTab === 'Request'}>
                Previous
              </Button>
              {activeTab === 'Request' && (
                <Button width='80px' variant='emphasized' type='submit'>
                  Next
                </Button>
              )}
              {activeTab !== 'Request' && (
                <Button
                  width='80px'
                  variant='emphasized'
                  onClick={handleNextButtonClick}
                  disabled={activeTab === 'Request overview'}
                >
                  Next
                </Button>
              )}

              <Button
                width='80px'
                variant='accent'
                color='primary'
                onClick={() => handleSend()}
                disabled={tabOptions['Request overview'] === true}
              >
                Send
              </Button>
            </Flex>
          </TitleBar.Action>
        </TitleBar>
        <Flex flexDirection='row' gap={8} alignItems='end' justifyContent='flex-end'></Flex>
        <Tabs selectedIndex={currentTabIndex} onChange={handleTabChange}>
          {requestTemplatesLoading || documentTypesLoading || reminderListLoading ? (
            <LoadingStateComponent loading={true} />
          ) : (
            <Tab
              title={'Request'}
              prefixIcon={
                Object.keys(createRequestErrors).length !== 0 || validationErrors['Request description'] ? (
                  <CriticalIcon style={{ color: Colors.Icon.Critical.Default }} />
                ) : (
                  <RequestIcon />
                )
              }
              disabled={tabOptions['Request']}
            >
              <Flex flexDirection='column' gap={16}>
                <Flex flexDirection='row' width={'80%'}>
                  <Controller
                    name='template'
                    control={control}
                    rules={{
                      required: {
                        value: false,
                        message: 'Please choose an option.',
                      },
                    }}
                    render={({ field }) => (
                      <FormField>
                        <Label>Select from template</Label>
                        <Flex
                          onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                            if (event.key === 'Enter') {
                              event.preventDefault(); // Prevent form submission
                            }
                          }}
                        >
                          <SelectV2
                            {...field}
                            // onChange={(value) => onDocumentTemplateChange('template', value)}
                            onChange={(value) => {
                              field.onChange(value);
                              handleTemplateChange(value);
                            }}
                          >
                            <SelectV2.Content width={'auto'}>
                              {requestTemplateData?.current.map(
                                (option: {
                                  requestTemplateId: React.Key | null | undefined;
                                  requestTemplateName:
                                    | string
                                    | number
                                    | boolean
                                    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                                    | Iterable<React.ReactNode>
                                    | React.ReactPortal
                                    | null
                                    | undefined;
                                }) => (
                                  <SelectV2.Option key={option.requestTemplateId} value={option.requestTemplateId}>
                                    {option.requestTemplateName}
                                  </SelectV2.Option>
                                ),
                              )}
                            </SelectV2.Content>
                          </SelectV2>
                        </Flex>
                      </FormField>
                    )}
                  />
                </Flex>
                <Flex flexDirection='column' gap={8}>
                  <Controller
                    name='requestName'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Request description is required',
                      },
                      minLength: {
                        value: 1,
                        message: '',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Request description</Label>
                        <TextInput
                          {...field}
                          value={field.value}
                          style={{ width: '80%' }}
                          placeholder='Enter your request description'
                          // onChange={(value: any) => handleChange('requestName', value)}

                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message || '',
                          }}
                        />
                      </FormField>
                    )}
                  />
                  <Controller
                    name='requestDescription'
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Description is required',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Request message</Label>
                        <TextArea
                          {...field}
                          value={field.value}
                          placeholder='Enter your message'
                          // onChange={(value: any) => handleChange('requestDescription', value)}
                          width={'80%'}
                          controlState={{
                            state: error ? 'error' : 'valid',
                          }}
                        />
                      </FormField>
                    )}
                  />
                </Flex>

                {fields.map((field, index) => (
                  <Flex
                    key={field.id}
                    flexDirection='column'
                    style={{
                      border: `1px solid ${Colors.Border.Neutral.Default}`,
                      borderRadius: Borders.Radius.Field.Default,
                      width: '80%',
                    }}
                    gap={8}
                  >
                    <Flex justifyContent='flex-end' style={{ marginRight: '8px', marginTop: '8px' }} gap={4}>
                      <Tooltip text={'Delete Document'}>
                        <Button
                          variant='emphasized'
                          aria-label='Remove'
                          style={{ textAlign: 'right', visibility: index > 0 ? 'visible' : 'hidden' }}
                          onClick={() => remove(index)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                    </Flex>

                    <Flex
                      flexDirection='column'
                      style={{ marginLeft: '24px', marginBottom: '16px', marginTop: '-24px' }}
                      gap={16}
                    >
                      <Controller
                        name={`requestDetails.${index}.label`}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'File name is required',
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <FormField required>
                            <Label>File name</Label>
                            <TextInput
                              {...field}
                              value={field.value}
                              style={{ width: '60%' }}
                              placeholder='Enter file name'
                              controlState={{
                                state: error ? 'error' : 'valid',
                                hint: error?.message || '',
                              }}
                            />
                          </FormField>
                        )}
                      />

                      <Controller
                        name={`requestDetails.${index}.documentTypeAutoId`}
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Please choose an option.',
                          },
                        }}
                        render={({ field, fieldState: { error } }) => (
                          <FormField required>
                            <Label>File type</Label>
                            <Flex
                              onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                                if (event.key === 'Enter') {
                                  event.preventDefault(); // Prevent form submission
                                }
                              }}
                            >
                              <SelectV2
                                {...field}
                                value={field.value}
                                controlState={{
                                  state: error ? 'error' : 'valid',
                                  hint: error?.message || '',
                                }}
                              >
                                <SelectV2.Content width={'auto'}>
                                  {documentTypes.current.map((option) => (
                                    <SelectV2.Option key={option.documentTypeAutoId} value={option.documentTypeAutoId}>
                                      {option.documentTypeName}
                                    </SelectV2.Option>
                                  ))}
                                </SelectV2.Content>
                              </SelectV2>
                            </Flex>
                          </FormField>
                        )}
                      />
                    </Flex>
                  </Flex>
                ))}
                <Flex flexDirection='column' gap={8}>
                  <Button style={{ color: Colors.Text.Primary.Default }} onClick={handleAddMore}>
                    Add more
                  </Button>
                </Flex>
              </Flex>
            </Tab>
          )}

          <Tab
            title='Recipients'
            prefixIcon={
              validationErrors['recipients'] ? (
                <CriticalIcon style={{ color: Colors.Icon.Critical.Default }} />
              ) : (
                <MailIcon />
              )
            }
            disabled={tabOptions['Recipients']}
          >
            <form onSubmit={handleSubmit(() => void 0)} noValidate style={{}}>
              <Flex flexDirection='column'>
                <Flex flexDirection='row' justifyContent='space-between' alignItems='center'>
                  <Flex justifyContent='flex-start'>
                    {validationErrors['recipients'] && (
                      <FormFieldMessages>
                        <Flex flexDirection='row' alignItems='center' rowGap={0}>
                          <CriticalIcon style={{ color: Colors.Icon.Critical.Default, verticalAlign: 'middle' }} />
                          <Label
                            style={{ color: Colors.Icon.Critical.Default, fontSize: '11px', verticalAlign: 'middle' }}
                          >
                            {validationErrors['recipients']}
                          </Label>
                        </Flex>
                      </FormFieldMessages>
                    )}
                  </Flex>

                  <Flex justifyContent='flex-end' gap={8}>
                    <Button
                      variant='accent'
                      onClick={() => {
                        addPerson();
                        setShowExistingPersonModal(true);
                      }}
                    >
                      Add person
                    </Button>
                    {showExistingPersonModal && (
                      <NewProjectPeopleRelationModal
                        key={uuidv4()}
                        parentType={PARENT_TYPE_PROJECT}
                        parentId={addPersonsVisible?.id ?? 0}
                        currentProjectPeopleList={peopleList}
                        currentBusinessPeopleList={[]}
                        onDismiss={(update) => {
                          setShowExistingPersonModal(false);
                          if (update) {
                            updateProjectPeopleRelations(addPersonsVisible ?? {});
                          }
                        }}
                      />
                    )}
                  </Flex>
                </Flex>

                <DataTable
                  data={receipents}
                  selectableRows={true}
                  columns={ReceipentColumns}
                  variant={{ contained: false }}
                  selectedRows={selectedRows}
                  onRowSelectionChange={handleRecipientsChange}
                />
              </Flex>
            </form>
          </Tab>

          <Tab title='Request overview' prefixIcon={<SourceIcon />} disabled={tabOptions['Request overview']}>
            <Flex flexDirection='column' gap={0}>
              <h3>Review Request:</h3>
              <Flex flexDirection='column' columnGap={0}>
                <Flex flexDirection='row'>
                  <p>
                    <Label>Request description:</Label>
                  </p>
                  <p>
                    <Text>{formValues.requestName}</Text>
                  </p>
                </Flex>
                <Flex flexDirection='row'>
                  <p>
                    <Label>Request Description:</Label>
                  </p>
                  <p>
                    <Text>{formValues.requestDescription}</Text>
                  </p>
                </Flex>
              </Flex>
              <Flex flexDirection='column' columnGap={0}>
                <h3>Receipents:</h3>

                <Flex flexDirection='row' columnGap={64}>
                  <Flex flexDirection='column' columnGap={0}>
                    <Label>Name</Label>
                    {formData.recipients.map((recipient, index) => (
                      <Text>
                        {recipient.firstName} {recipient.lastName}
                      </Text>
                    ))}
                  </Flex>
                  <Flex flexDirection='column' columnGap={0}>
                    <Label>Email</Label>
                    {formData.recipients.map((recipient, index) => (
                      <Text>{recipient.email}</Text>
                    ))}
                  </Flex>
                </Flex>
              </Flex>

              <Flex flexDirection='column' gap={4}>
                <h3>List of Requested Documents:</h3>
                {formValues?.requestDetails?.map((section, index) => (
                  <Flex
                    width={'80%'}
                    key={index}
                    flexDirection='column'
                    columnGap={0}
                    style={{
                      border: `1px solid ${Colors.Border.Neutral.Default}`,
                      borderRadius: Borders.Radius.Field.Default,
                      padding: '8px',
                    }}
                  >
                    <Flex flexDirection='row' gap={8}>
                      <p>
                        <Label>Document Id No:</Label>
                      </p>
                      <p>#{index + 1}</p>
                    </Flex>
                    <Flex flexDirection='row' gap={8}>
                      <p>
                        <Label>File name:</Label>
                      </p>
                      <p>{section.label}</p>
                    </Flex>
                    <Flex flexDirection='row' gap={8}>
                      <p>
                        <Label>File Type:</Label>
                      </p>
                      <p>{getDocumentTypeName(section?.documentTypeAutoId)}</p>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          </Tab>
        </Tabs>
      </form>
      <FormFieldMessages />
    </Sheet>
  );
};

export default CreateRequest;

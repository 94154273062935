import { useRef, useState } from 'react';
import { Page } from '@dynatrace/strato-components-preview';
import { LoadingStateComponent } from './components/LoadingStateComponent';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppInfo } from './contexts/AppContext';
import { AppInfo } from './types/AppInfo';
import { UserService } from './services/UsersService';
import { TenantService } from './services/TenantService';
import { ShowErrorNotification } from './utils/Notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Tenant } from './types/Tenant';
import { ProjectService } from './services/ProjectService';
import { IndustryDetails } from './types/Industry';
import { LoanTypeDetails } from './types/Loan';
import { PersonaService } from './services/PersonaService';
import { PeopleService } from './services/PeopleService';
import { User as MinervaUserData } from './types/User';
import { Persona } from './types/Personas';
import LandingPage from './pages/upload/LandingPage';
import { NotAuthorizedModal } from './NotAuthorizedModal';
import { updateMinervaUserToken } from './utils/Utilities';
import { AppRoutes } from './AppRoutes';
import { UserInfo } from './types/UserInfo';
import { useLocation } from 'react-router-dom';
import { TenantSelectionModal } from './TenantSelectionModal';
import { TemplateService } from './services/TemplateService';

export const App = () => {
  const locatioN = useLocation();
  const { isLoading, isAuthenticated, user, getIdTokenClaims } = useAuth0();
  const [showSelectTenant, setShowSelectTenant] = useState(false);
  const [userVerified, setUserVerified] = useState(false);

  const userTokenRef = useRef<string | undefined | null>(null);
  const { updateAppInfo } = useAppInfo();
  const appInfo = {} as AppInfo;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  if (isLoading) {
    return (
      <Page>
        <Page.Main>
          <LoadingStateComponent loading={true} />
        </Page.Main>
      </Page>
    );
  }

  if (locatioN.pathname.startsWith('/upload')) {
    return (
      <Page>
        <Page.Main>
        <QueryClientProvider client={queryClient}>
          <LandingPage token={locatioN.pathname.split('/upload/')[1]} />
          </QueryClientProvider>
        </Page.Main>
      </Page>
    );
  } else if (isAuthenticated && !userVerified &&  user?.['roles/roles']?.includes('Admin')) {
      setShowSelectTenant(true);
      setUserVerified(true);
  } else if (isAuthenticated) {
    const handleAsyncCalls = async () => {
      try {
        const id_token = await getIdTokenClaims(); // Get id token
        const user_token = id_token?.__raw;
        if (userTokenRef.current !== user_token) {
          updateMinervaUserToken(user_token);

          // if user does not have any roles then there is no point in taking him into the application
          if (user?.['roles/roles']?.length !== 0 && !showSelectTenant) {
            const userData: MinervaUserData = await UserService.getUserByUserName(user?.email ?? '');

            const tenantId = userData?.tenantId;
            appInfo.tenantId = tenantId;

            userTokenRef.current = user_token;

            const now = new Date();
            appInfo.expiry = now.getTime() + 2000;
            appInfo.loginToken = user_token;

            appInfo.isUserLoggedIn = true;
            appInfo.userInfo = {} as UserInfo;
            appInfo.userInfo.username = user?.preferred_username;
            appInfo.userInfo.profile = user?.profile;
            appInfo.userInfo.userData = userData;
            appInfo.userInfo.picture = user?.picture;

            appInfo.userInfo.isAdmin = user?.['roles/roles']?.includes('Admin');
            appInfo.userInfo.isTenantAdmin = user?.['roles/roles']?.includes('TenantAdmin');
            appInfo.userInfo.isStaff = user?.['roles/roles']?.includes('Staff');

            // Create an array of promises
            Promise.all([
              TenantService.getTenantById<Tenant>(tenantId),
              ProjectService.getIndustries<IndustryDetails[]>(),
              ProjectService.getLoanTypes<LoanTypeDetails[]>(),
              PersonaService.getPersonaByTenantList<any>(tenantId),
              PersonaService.getPeoplePersona<Persona[]>(tenantId),
              TenantService.getStatusByTenant<any>(tenantId, 0),
              PeopleService.getStates(),
              UserService.getUsers(),
              TemplateService.getDocumentListByTenantId(tenantId),
            ]).then(
              ([
                tenantDetails,
                industryData,
                loanTypeData,
                personasData,
                peoplePersonas,
                statusData,
                stateData,
                usersData,
                documentTypeData,
              ]) => {
                // Process the results
                appInfo.tenant = tenantDetails;
                const industryTenant = industryData.filter((industry: any) => industry.tenantId === tenantId);
                appInfo.tenantIndustries = industryTenant;
                appInfo.tenantLoanTypes = loanTypeData;
                appInfo.tenantPersonaList = personasData.personas;
                appInfo.tenantProjectStatusList = statusData.statuses;
                appInfo.tenantStateList = stateData;
                appInfo.users = usersData;
                appInfo.documentTypes = documentTypeData;
                appInfo.peoplePersonas = peoplePersonas;
                updateAppInfo(appInfo);
              },
            );
          }
        }
      } catch (error: any) {
        ShowErrorNotification('Error initializing application', error);
      } finally {
      }
    };
    handleAsyncCalls();
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AppRoutes />
      {isAuthenticated && user?.['roles/roles']?.length === 0 && <NotAuthorizedModal />}
      {isAuthenticated && showSelectTenant && <TenantSelectionModal close={() => setShowSelectTenant(false)} />}
    </QueryClientProvider>
  );
};

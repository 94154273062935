import { AxiosError } from 'axios';
import { getMinervaUserToken } from '../utils/Utilities';

export interface ApiResponse {
  data: any;
}

export const handleApiError = (error: AxiosError) => {
  if (error.response) {
    console.error('API Error Response:', error.response.data);
  } else if (error.request) {
    console.error('API No Response:', error.request);
  } else {
    console.error('API Error:', error.message);
  }
};

export function getBaseURL(): string {
  const url =  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? process.env.REACT_API_BASE_URL ?? 'https://localhost:7166'
    : 'https://dev-api.minerva.zyq.ai';

    return url;
}

export function getAuthHeader(): any {
  return { headers: { Authorization: 'Bearer ' + getMinervaUserToken() } };
}

import axios from 'axios';
import { getAuthHeader, getBaseURL, handleApiError } from './AxiosAuthService';

export const NotesService = {
    createStaffNotes: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/ProjectNotes`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, deleteStaffNotes: async <T>(notesId: any): Promise<T> => {
        try {
            const response: any = await axios.delete(`${getBaseURL()}/ProjectNotes/${notesId}`, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    }, updateStaffNotes: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.put(`${getBaseURL()}/ProjectNotes`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
}
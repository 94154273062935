import axios from 'axios';
import { getAuthHeader, getBaseURL, handleApiError } from './AxiosAuthService';// Replace with your API endpoint

export const UploadService = {
    postProjectEmailDetails: async <T>(token: any): Promise<T> => {
        try {
            const request = { token: token};
            const response: any = await axios.post(`${getBaseURL()}/ProjectRequest/projectRequestEmailDetails`, request);
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    postProjectRequestUploadFiles: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/ProjectRequest/projectRequestUploadFiles`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
    projectRequestUploadFileForStaff: async <T>(request: any): Promise<T> => {
        try {
            const response: any = await axios.post(`${getBaseURL()}/ProjectRequest/projectRequestUploadFileForStaff`, request, getAuthHeader());
            return response.data;
        } catch (error: any) {
            handleApiError(error);
            throw error;
        }
    },
}
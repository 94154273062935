import { ApplicationSecuritySignetIcon, ChartCollectionIcon, MoneyIcon, GroupIcon } from '@dynatrace/strato-icons';
import { ComponentType } from 'react';

export enum CONFIG_ROUTES {
  PROJECTS = 'projects',
  PROJECT = 'project',
  LENDERS = 'lenders',
  PEOPLE = 'people',
  TENANT = 'tenant',
  REQ_TEMPLATE = 'req-template',
  BUSINESSES = 'businesses',
}

export interface ConfigRoute {
  id: CONFIG_ROUTES;
  friendlyName: string;
  desc: string;
  routeName: string;
  icon: ComponentType;
  parent?: ConfigRoute;
}

const projectRoute: ConfigRoute = {
  id: CONFIG_ROUTES.PROJECTS,
  friendlyName: 'Projects',
  desc: 'View and manage your projects',
  routeName: '/projects',
  icon: ChartCollectionIcon,
};

export const configRoutes: ConfigRoute[] = [
  projectRoute,
  {
    id: CONFIG_ROUTES.BUSINESSES,
    friendlyName: 'Businesses',
    desc: 'View and manage your businesses',
    routeName: '/businesses',
    icon: ApplicationSecuritySignetIcon,
  },
  {
    id: CONFIG_ROUTES.LENDERS,
    friendlyName: 'Lenders',
    desc: 'View and manage your lenders',
    routeName: '/lenders',
    icon: MoneyIcon,
  },
  {
    id: CONFIG_ROUTES.PEOPLE,
    friendlyName: 'People',
    desc: 'View and manage people',
    routeName: '/people',
    icon: GroupIcon,
  },
  {
    id: CONFIG_ROUTES.PROJECT,
    friendlyName: 'Project Details',
    desc: 'View project details',
    routeName: '/projects/project/:ProjectId',
    icon: ChartCollectionIcon,
    parent: projectRoute,
  },
  //{
  //  id: CONFIG_ROUTES.REQ_TEMPLATE,
  //  friendlyName: "Request Templates",
  //  routeName: "/request-templates",
  //  icon: TemplateIcon,
  //},
  //{
  //  id: CONFIG_ROUTES.TENANT,
  //  friendlyName: "Tenant",
  //  routeName: "/tenant",
  //  icon: AppsIcon,
  //},
];

import axios from 'axios';
import { getAuthHeader, getBaseURL, handleApiError } from './AxiosAuthService';
import { DocumentType } from '../types/DocumentType';
import { RequestTemplate } from '../types/RequestTemplate';

export const TemplateService = {
  getReminderList: async <T>(): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/Reminder`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getReminderListByTenantId: async <T>(tenantId: any): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/Reminder/tenant/` + tenantId, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getDocumentList: async <T>(): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/documenttype`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getDocumentListByTenantId: async (tenantId: any): Promise<DocumentType[]> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/documenttype/tenantId/` + tenantId, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  postRequestTemplates: async <T>(request: any): Promise<T> => {
    try {
      const response: any = await axios.post(`${getBaseURL()}/requesttemplate`, request, getAuthHeader());
      return response.data.documentTypes;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getProjectDocumentTemplates: async (): Promise<RequestTemplate[]> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/requesttemplate`, getAuthHeader());
      return response.data.requestTemplates;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  getRequestDetailsById: async <T>(id: number): Promise<T> => {
    try {
      const response: any = await axios.get(`${getBaseURL()}/requesttemplate/${id}`, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
  updateRequestTemplates: async <T>(request: any): Promise<T> => {
    try {
      const response: any = await axios.put(`${getBaseURL()}/requesttemplate`, request, getAuthHeader());
      return response.data;
    } catch (error: any) {
      handleApiError(error);
      throw error;
    }
  },
};

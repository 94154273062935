import { Button, Flex, Text } from '@dynatrace/strato-components';
import {
  DataTable,
  Tooltip,
  TableRowActions,
  Page,
  TitleBar,
  SelectV2,
  Avatar,
  AvatarGroup,
  Label,
  Tabs,
  Tab,
} from '@dynatrace/strato-components-preview';
import {
  EditIcon,
  HomeIcon,
  CloseSidebarIcon,
  OpenSidebarIcon,
  DownloadIcon,
  UploadIcon,
  PlusIcon,
  RefreshIcon,
} from '@dynatrace/strato-icons';
import { useParams } from 'react-router-dom';
import { Colors } from '@dynatrace/strato-design-tokens';
import { useMemo, useRef, useState } from 'react';
import { ItemInfo } from '../../types/ListItemInfo';
import { ProjectSheet } from './ProjectSheet';
import { ProjectService } from '../../services/ProjectService';
import { RequestDetails } from '../../types/Request';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { NotesDetails } from '../../types/Notes';
import { NotesService } from '../../services/NotesService';
import { useAppInfo } from '../../contexts/AppContext';
import CreateRequest from './CreateRequest';
import { UploadService } from '../../services/UploadService';
import { configRoutes } from '../../configs/constants';
import { format, parseISO } from 'date-fns';
import { useProjectRequestById, useProjectWithDetailsID, useStatusByTenant } from '../../hooks/use-minerva-data';
import { groupDocumentsByProjectRequestId, GroupedDocuments, Notes, truncateText } from './project-details-utils';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { ProjectPeopleRelation } from '../../types/Project';
import { StatusDetails } from '../../types/Status';

export interface ProjectDetailsProps {}

export const ProjectDetails = () => {
  const { ProjectId } = useParams();
  const [editDetailsVisible, setEditDetailsVisible] = useState<ItemInfo | undefined>();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [newNotes, setNewNotes] = useState(false);
  const [addNewNotes, setAddNewNotes] = useState('');
  const [createRequestSheet, setCreateRequestSheet] = useState(false);
  const [expandedNoteIndex, setExpandedNoteIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const fileInputRefs = useRef<{ [key: number]: (HTMLInputElement | null)[] }>({});
  const peopleList = useRef<ProjectPeopleRelation[]>([]);
  const notes = useRef<NotesDetails[]>([]);
  const statusOptions = useRef<StatusDetails[]>([]);
  const projectRequests = useRef<RequestDetails[]>([]);
  const [projectRequestsApiData, setProjectRequestsApiData] = useState<GroupedDocuments[]>([] as GroupedDocuments[]);
  const { tenantId, userId, user } = useAppInfo();

  // table main columns
  const columns = useMemo(
    () => [
      {
        id: 'projectRequestId',
        header: 'Request Id',
        accessor: 'projectRequestId',
        autoWidth: true,
        columnType: 'number',
      },
      {
        id: 'projectRequestName',
        header: 'Request name',
        accessor: 'projectRequestName',
        width: 280,
      },
      {
        id: 'createdOn',
        header: 'Created on',
        accessor: 'createdOn',
        columnType: 'date',
        width: 150,
        Cell: ({ value }: any) => {
          try {
            const date = parseISO(value);
            return format(date, 'MMM dd, yyyy HH:mm');
          } catch (error) {
            return 'Invalid date';
          }
        },
      },
      {
        id: 'assignedCount',
        header: 'Receipients count',
        accessor: 'assignedCount',
        width: 100,
        columnType: 'number',
      },
    ],
    [],
  );

  // table sub-row columns
  const rowColumns = useMemo(
    () => [
      {
        id: 'assignedto',
        header: 'Assigned to',
        accessor: 'assignedto',
        width: 250,
      },
      {
        id: 'completed',
        header: 'Completed',
        accessor: 'completed',
        autoWidth: true,
        columnType: 'number',
      },
      {
        id: 'inProgress',
        header: 'In-progress',
        accessor: 'inProgress',
        autoWidth: true,
        columnType: 'number',
      },
    ],
    [],
  );

  // custom renderer for status cell for document rows.
  const renderStatusNameCell = ({ cell }: any) => {
    const statusValue = cell.value;
    const disableStatusCheck = (statusValue: string) => {
      if (statusValue === 'Sent' || statusValue === 'Approved') {
        return true;
      }
      return false;
    };
    const disableOptionsCheck = (option: string) => {
      if (option === 'Sent' || option === 'Received') {
        return true;
      }
      return false;
    };

    return (
      <Flex
        onKeyDown={(event: { key: string; preventDefault: () => void }) => {
          if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
          }
        }}
      >
        <SelectV2
          value={cell.value}
          onChange={(value: string) => handleSelectChange(value, statusOptions.current, cell)}
          disabled={disableStatusCheck(statusValue)}
        >
          <SelectV2.Content width={'auto'}>
            {statusOptions?.current?.map((option) => (
              <SelectV2.Option
                key={option.statusName}
                value={option.statusName}
                disabled={disableOptionsCheck(option.statusName)}
              >
                {option.statusName}
              </SelectV2.Option>
            ))}
          </SelectV2.Content>
        </SelectV2>
      </Flex>
    );
  };
  const rowDocumentsColumns = useMemo(
    () => [
      {
        id: 'label',
        header: 'File name',
        accessor: 'label',
        width: 220,
      },
      {
        id: 'documentTypeName',
        header: 'File type',
        accessor: 'documentTypeName',
        width: 200,
      },
      {
        id: 'statusName',
        header: 'Status',
        accessor: 'statusName',
        autoWidth: true,
        cell: renderStatusNameCell,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // get project details
  const {
    isLoading: projectDetailsLoading,
    isRefetching: projectDetailsRefetching,
    data: projectDetailsData,
    error: projectDetailsError,
    refetch: refetchProjectDetails,
  } = useProjectWithDetailsID(Number(ProjectId));

  if (projectDetailsError) {
    ShowErrorNotification('Error loading project details', projectDetailsError);
  }

  // get project requests custom hook
  const {
    isLoading: projectRequestsLoading,
    isRefetching: projectRequestsRefetching,
    data: projectRequestsData,
    error: requestError,
    refetch: refetchProjectRequests,
  } = useProjectRequestById(Number(ProjectId));
  if (requestError) {
    ShowErrorNotification('Error loading project requests', requestError);
  }

  // get status Details
  const {
    isLoading: statusDetailsLoading,
    data: statusDetailsData,
    error: statusDetailsError,
    // refetch: refetchStatusDetails,
  } = useStatusByTenant(Number(tenantId), 1);

  if (statusDetailsError) {
    ShowErrorNotification('Error loading project details', projectDetailsError);
  }

  if (
    !projectDetailsLoading &&
    !projectRequestsLoading &&
    !projectRequestsRefetching &&
    !projectDetailsRefetching &&
    !statusDetailsLoading
  ) {
    //notes
    projectRequests.current = projectRequestsData.projectRequest;
    statusOptions.current = statusDetailsData?.statuses;
    peopleList.current = projectDetailsData?.projectPeopleRelation ?? [];

    const notesDesc = projectDetailsData?.notes.sort(
      (a: any, b: any) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    );
    notes.current = notesDesc || [];

    //requests
    const projectRequestsWithDocuments = groupDocumentsByProjectRequestId(projectRequestsData.projectRequest);
    const projectRequestsWithDocumentsDesc = projectRequestsWithDocuments.sort(
      (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    );
    if (JSON.stringify(projectRequestsApiData) !== JSON.stringify(projectRequestsWithDocumentsDesc)) {
      setProjectRequestsApiData(projectRequestsWithDocumentsDesc);
    }
  }

  const handleUploadClick = (requestId: number, projectRequestSentId: number, documentIndex: number) => {
    if (fileInputRefs.current[requestId] && fileInputRefs.current[requestId][documentIndex]) {
      fileInputRefs.current[requestId][documentIndex]!.click();
    }
  };

  const AddNewNotesClick = async () => {
    const requestBody: NotesDetails = {
      projectNotesId: 0,
      projectId: projectDetailsData?.project.projectId ?? 0,
      tenantId: tenantId,
      notes: addNewNotes,
      createdByUserId: userId ?? '',
      createdOn: new Date().toISOString(),
      createdByName: user?.firstName + ' ' + user?.lastName,
    };
    try {
      const data: any = await NotesService.createStaffNotes<NotesDetails>(requestBody);
      ShowSuccessNotification('Notes added successfully');
      requestBody.projectNotesId = data?.projectNotesId;
      notes.current = [requestBody, ...notes.current];
      setNewNotes(false); // Reset addNewNotes state after adding the note
      setAddNewNotes('');
      refetchProjectDetails();
    } catch (error) {
      ShowErrorNotification('Error occurred while adding notes', error);
    } finally {
    }
  };

  const downloadFileClick = function (rowInfo: any) {
    //In Local, uncomment the below line
    // window.open(ProjectService.getDownloadFileHeader(rowInfo.original.downloadFilePath));
    //In Dev, uncomment the below Line
    window.open(ProjectService.getDownloadFileHeader(rowInfo.downloadFilePath));
  };

  const editProjectSheet = () => {
    setEditDetailsVisible({
      id: parseInt(ProjectId ?? '0'),
      name: projectDetailsData?.project.projectName,
      visibility: true,
    });
  };

  const handleCreateRequest = async (newRequest: any) => {
    setProjectRequestsApiData((prev: any) => [newRequest, ...prev]);
    refetchProjectRequests();
  };

  const CreateRequestClick = () => {
    setCurrentTabIndex(0);
    setCreateRequestSheet(true);
  };

  const handleExpandClick = (index: number) => {
    if (expandedNoteIndex === index) {
      setExpandedNoteIndex(null); // Collapse if already expanded
    } else {
      setExpandedNoteIndex(index); // Expand the clicked note
    }
  };

  const truncateNotesText = (text: string, index: number) => {
    const maxLength = 100; // Set your desired truncation length
    if (text.length > maxLength && expandedNoteIndex !== index) {
      return (
        <>
          {text.substring(0, maxLength)}...
          <Button
            variant='default'
            color='neutral'
            style={{ fontSize: '11px' }}
            onClick={() => handleExpandClick(index)}
          >
            Expand
          </Button>
        </>
      );
    } else {
      return (
        <>
          {text}
          {text.length > maxLength && (
            <Button
              variant='default'
              color='neutral'
              style={{ fontSize: '11px' }}
              onClick={() => handleExpandClick(index)}
            >
              Collapse
            </Button>
          )}
        </>
      );
    }
  };

  const handleSelectChange = async (updatedStatus: string, statusOptions: any, cell: any) => {
    // let selectedName = updatedStatus;
    let selectedValue = '';
    statusOptions.forEach((option: { statusName: string; statusAutoId: any }) => {
      if (updatedStatus === option.statusName) {
        selectedValue = option.statusAutoId;
      }
    });

    const reqeust = {
      projectRequestSentId: cell.row.original.projectRequestSentId,
      statusAutoId: selectedValue + '',
    };

    try {
      const data: any = await ProjectService.updateProjectRequestStatus(reqeust);
      if (data) {
        refetchProjectRequests();
        ShowSuccessNotification('Update Status Successfully ');
      }
    } catch (error) {
      ShowErrorNotification('Error updating status ', error);
    } finally {
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    requestId: number,
    uploadRow: any,
    documentIndex: number,
    projectRequests: RequestDetails[],
  ) => {
    const files = event.target.files;
    const uploadRequest = projectRequests.filter((e) => e.projectRequestSentId === uploadRow.projectRequestSentId)[0];
    projectRequestsApiData.map((request) => {
      if (request.projectRequestId === requestId) {
        return {
          ...request,
          assignedList: request.assignedList.map((assigned) => {
            if (assigned.documents.some((doc) => doc.projectRequestSentId === uploadRow.projectRequestSentId)) {
              return {
                ...assigned,
                documents: assigned.documents.map((doc) => {
                  if (
                    doc.projectRequestSentId === uploadRow.projectRequestSentId &&
                    doc.documentTypeName === uploadRequest.documentTypeName
                  ) {
                    return {
                      ...doc,
                      // Update any other necessary fields in the document here
                      statusName: 'Received',
                    };
                  }
                  return doc;
                }),
              };
            }
            return assigned;
          }),
        };
      }
      return request;
    });

    if (files && files.length > 0) {
      const file = files[0];
      if (file) {
        try {
          setLoading(true);
          const reader = new FileReader();
          reader.onload = async () => {
            const fileDataUrl = reader.result as string;
            const base64Data = fileDataUrl.split(',')[1];
            const mimeTypeSegment = fileDataUrl.split(',')[0];
            const mimeType = mimeTypeSegment.split(':')[1].split(';')[0];
            const fileExtension = mimeType.split('/')[1];
            const token = '';
            const tenantId = uploadRequest.tenantId;
            const projectId = uploadRequest.projectId;
            const projectRequestId = uploadRequest.projectRequestId;
            const projectRequestSentId = uploadRequest.projectRequestSentId;
            const peopelId = uploadRequest.peopleId;
            const documentTypeAutoId = uploadRequest.documentTypeAutoId;
            const fileext = '.' + fileExtension;
            // const label = uploadRequest.label;

            const params = {
              token: token,
              tenantId: tenantId,
              projectId: projectId,
              projectRequestId: projectRequestId,
              fileData: base64Data,
              projectRequestSentId: projectRequestSentId,
              peopelId: peopelId,
              documentTypeAutoId: documentTypeAutoId,
              fileext: fileext,
              fileName: `${file.name.split('.')[0]}`,
            };

            const data = await UploadService.projectRequestUploadFileForStaff(params);
            if (data) {
              refetchProjectRequests();
              ShowSuccessNotification('File uploaded successfully');
            }

            // setProjectRequestsApiData(updatedData); // --------
          };

          reader.readAsDataURL(file);
        } catch (error) {
          ShowErrorNotification('Error while uploading file', error);
        } finally {
          setLoading(false);
          setProjectRequestsApiData((prevData) =>
            // eslint-disable-next-line react-hooks/exhaustive-deps
            prevData.map((request: any) => {
              if (request.projectRequestId === requestId) {
                return {
                  ...request,
                  assignedList: request.assignedList.map((assigned: { documents: any[] }) => {
                    if (assigned.documents.some((doc) => doc.projectRequestSentId === uploadRow.projectRequestSentId)) {
                      return {
                        ...assigned,
                        documents: assigned.documents.map((doc) => {
                          if (
                            doc.projectRequestSentId === uploadRow.projectRequestSentId &&
                            doc.documentTypeName === uploadRequest.documentTypeName
                          ) {
                            return {
                              ...doc,
                              // Update any other necessary fields in the document here
                              statusName: 'Received',
                            };
                          }
                          return doc;
                        }),
                      };
                    }
                    return assigned;
                  }),
                };
              } else {
                return request;
              }
            }),
          );
        }
      }
    }
  };

  const refreshRequests = () => {
    setCurrentTabIndex(0);
    refetchProjectDetails();
    refetchProjectRequests();
  };

  return (
    <>
      <CreateRequest
        isOpen={createRequestSheet}
        onClose={function (): void {
          setCreateRequestSheet(!createRequestSheet);
        }}
        peopleList={peopleList.current}
        projectDetails={projectDetailsData}
        onRequestCreated={handleCreateRequest}
      />
      <TitleBar>
        <TitleBar.Prefix>
          <Page.PanelControlButton target='sidebar' />
        </TitleBar.Prefix>
        <TitleBar.Title>{projectDetailsData?.project.projectName ?? ''}</TitleBar.Title>
        <TitleBar.Subtitle>{configRoutes[4].desc} </TitleBar.Subtitle>
        <TitleBar.Suffix>
          <Flex gap={8}>
            <Button
              onClick={() => CreateRequestClick()}
              variant='accent'
              color='primary'
              style={{ margin: '0px 0px 0px auto' }}
            >
              <Button.Prefix>
                <PlusIcon />
              </Button.Prefix>
              Create a request
            </Button>
            <Button variant='emphasized' color='neutral' onClick={refreshRequests}>
              <Button.Prefix>
                <RefreshIcon />
              </Button.Prefix>
              Refresh
            </Button>
          </Flex>
        </TitleBar.Suffix>
      </TitleBar>
      {(projectRequestsLoading ||
        projectDetailsLoading ||
        projectDetailsRefetching ||
        projectRequestsRefetching ||
        loading) && <LoadingStateComponent loading={true} />}
      <Flex flexDirection='column' margin={8} padding={0} gap={8}>
        <Flex flexDirection='row' margin={8} padding={0} gap={8} style={{ marginTop: '-4px', height: '80vh' }}>
          <Flex flexDirection='column' width={isCollapsed ? '3%' : '25%'}>
            <div
              style={{
                padding: '5px',
                border: isCollapsed ? '' : `1px solid ${Colors.Border.Neutral.Default}`,
                borderRadius: '8px',
                marginBottom: '16px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: isCollapsed ? '20%' : '100%',
                  alignContent: 'baseline',
                }}
              >
                {!isCollapsed && (
                  <div style={{ width: '40%' }}>
                    <label style={{ color: Colors.Text.Neutral.Default }}>Project information</label>
                  </div>
                )}
                {!isCollapsed && (
                  <div style={{ width: '40%', height: '5%' }}>
                    <Tooltip text={'Edit'}>
                      <Button style={{ fontSize: '12px' }} color='primary' onClick={editProjectSheet} variant='accent'>
                        <Button.Prefix>
                          <EditIcon />
                        </Button.Prefix>
                        Edit
                      </Button>
                    </Tooltip>
                  </div>
                )}
                <div style={{ width: '20%', height: '5%', textAlign: 'right' }}>
                  {!isCollapsed && (
                    <Tooltip text={'Close Project Info'}>
                      <Button onClick={() => setIsCollapsed(!isCollapsed)} style={{ marginTop: '4px' }}>
                        <Button.Prefix>
                          <CloseSidebarIcon />
                        </Button.Prefix>
                      </Button>
                    </Tooltip>
                  )}
                  {isCollapsed && (
                    <Tooltip text={'Open Project Info'}>
                      <Button onClick={() => setIsCollapsed(!isCollapsed)} style={{ marginTop: '4px' }}>
                        <Button.Prefix>
                          <OpenSidebarIcon />
                        </Button.Prefix>
                      </Button>
                    </Tooltip>
                  )}
                </div>
              </div>

              <br />
              {!isCollapsed && (
                <>
                  <Flex height='80vh' style={{ overflowY: 'auto' }} flexDirection='column' gap={12}>
                    <Flex flexDirection='column' gap={2}>
                      <Label style={{ color: Colors.Text.Primary.Disabled }}>Project description</Label>
                      <Text style={{ color: Colors.Text.Neutral.Default, whiteSpace: 'pre-wrap' }}>
                        {showFullText
                          ? projectDetailsData?.project.projectDescription
                          : truncateText(projectDetailsData?.project?.projectDescription || '', 1)}
                        {!showFullText && truncateText(projectDetailsData?.project?.projectDescription || '', 1) !== projectDetailsData?.project?.projectDescription  && (
                          <Button
                            variant='default'
                            color='neutral'
                            onClick={() => setShowFullText(true)}
                            style={{ marginTop: '-10px', fontSize: '11px' }}
                          >
                            <u>Expand</u>
                          </Button>
                        )}
                        {showFullText && (
                          <Button
                            variant='default'
                            color='neutral'
                            onClick={() => setShowFullText(false)}
                            style={{ marginTop: '-10px', fontSize: '11px' }}
                          >
                            <u>Collapse</u>
                          </Button>
                        )}
                      </Text>
                    </Flex>

                    <Flex flexDirection='column' gap={2}>
                      <Label style={{ color: Colors.Text.Primary.Disabled }}>Status:</Label>
                      <Text style={{ color: Colors.Text.Neutral.Default }}>
                        {projectDetailsData?.status?.statusName}
                      </Text>
                    </Flex>
                    <Flex flexDirection='column' gap={2}>
                      <Label style={{ color: Colors.Text.Primary.Disabled }}>Loan amount:</Label>
                      <Text style={{ color: Colors.Text.Neutral.Default }}>
                        <b>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(parseInt(projectDetailsData?.project.amount ?? '', 10))}
                        </b>
                      </Text>
                    </Flex>
                    <Flex flexDirection='column' gap={2}>
                      <Label style={{ color: Colors.Text.Primary.Disabled }}>Purpose</Label>
                      <Text style={{ color: Colors.Text.Neutral.Default }}>{projectDetailsData?.project.purpose}</Text>
                    </Flex>
                    <Flex flexDirection='column' gap={2}>
                      <Label style={{ color: Colors.Text.Primary.Disabled }}>Assigned staff:</Label>
                      <Text style={{ color: Colors.Text.Neutral.Default }}>
                        {projectDetailsData?.project.assignedToName}
                      </Text>
                    </Flex>
                    <Flex flexDirection='column' gap={2}>
                      <Label style={{ color: Colors.Text.Primary.Disabled }}>Primary borrower:</Label>
                      {projectDetailsData?.projectPeopleRelation !== undefined && (
                        <Flex flexDirection='row'>
                          {projectDetailsData?.projectPeopleRelation?.length === 0 ? (
                            <Text style={{ color: Colors.Text.Neutral.Default }}>N/A</Text>
                          ) : (
                            <Tooltip
                              text={
                                projectDetailsData?.projectPeopleRelation[0]?.lastName +
                                ', ' +
                                projectDetailsData?.projectPeopleRelation[0]?.firstName
                              }
                            >
                              <Avatar
                                abbreviation={
                                  projectDetailsData?.projectPeopleRelation[0]?.lastName?.toUpperCase() +
                                  ' ' +
                                  projectDetailsData?.projectPeopleRelation[0]?.firstName?.toUpperCase()
                                }
                              />
                            </Tooltip>
                          )}
                        </Flex>
                      )}
                    </Flex>
                    <Flex flexDirection='column' gap={2}>
                      <Label style={{ color: Colors.Text.Primary.Disabled }}>Sponsors:</Label>
                      <Flex flexDirection='row'>
                        {projectDetailsData?.projectPeopleRelation?.length === 0 && (
                          <Text style={{ color: Colors.Text.Neutral.Default }}>N/A</Text>
                        )}
                        <AvatarGroup>
                          {projectDetailsData?.projectPeopleRelation?.length === 0 && (
                            <Text style={{ color: Colors.Text.Neutral.Default }}>N/A</Text>
                          )}
                          {projectDetailsData?.projectPeopleRelation?.map(
                            (person, index) =>
                              index !== 0 && (
                                <AvatarGroup.Item
                                  abbreviation={
                                    (person?.lastName?.[0]?.toUpperCase() || '') +
                                    (person?.firstName?.[0]?.toUpperCase() || '')
                                  }
                                  tooltipText={`${person?.lastName}, ${person?.firstName}`}
                                  key={`${person?.lastName}, ${person?.firstName}`}
                                />
                              ),
                          )}
                        </AvatarGroup>
                      </Flex>
                    </Flex>
                    <Flex flexDirection='column' gap={2}>
                      <Label style={{ color: Colors.Text.Primary.Disabled }}>Primary business:</Label>
                      <Flex flexDirection='row'>
                        <HomeIcon />
                        <Text style={{ color: Colors.Text.Neutral.Default }}>
                          {projectDetailsData?.projectBusinessesRelation !== undefined &&
                          projectDetailsData?.projectBusinessesRelation?.length > 0
                            ? projectDetailsData?.projectBusinessesRelation[0]?.businessName
                            : 'N/A'}
                        </Text>
                      </Flex>
                    </Flex>
                    {
                      //MSD-68: Drop this field from the project.
                      //<Flex flexDirection='column' gap={2}>
                      // <Label style={{ color: Colors.Text.Primary.Disabled }}>Industry Sector:</Label>
                      // <Text style={{ color: Colors.Text.Neutral.Default }}>
                      //   {' '}
                      //   {projectDetailsData?.industry != null ? projectDetailsData?.industry?.industrySector : 'N/A'}
                      // </Text>
                      //</Flex>
                    }
                    <Flex flexDirection='column' gap={2}>
                      <Label style={{ color: Colors.Text.Primary.Disabled }}>Loan type:</Label>
                      <Text style={{ color: Colors.Text.Neutral.Default }}>
                        {projectDetailsData?.loanType.loanType}
                      </Text>
                    </Flex>
                    <Flex flexDirection='column' gap={2}>
                      <Label style={{ color: Colors.Text.Primary.Disabled }}>Desired closing date:</Label>
                      <Text style={{ color: Colors.Text.Neutral.Default }}>
                        {projectDetailsData?.project.desiredClosedDate
                          ? format(parseISO(projectDetailsData?.project.desiredClosedDate), 'MMM dd, yyyy')
                          : 'N/A'}
                      </Text>
                    </Flex>
                  </Flex>
                </>
              )}
            </div>
            &nbsp;
          </Flex>
          <Flex flexDirection={'column'} width={isCollapsed ? '100%' : '80%'}>
            <Tabs selectedIndex={currentTabIndex} style={{ width: '100  %' }} onChange={setCurrentTabIndex}>
              <Tab title='Requests'>
                <Flex flexDirection='column' style={{ overflow: 'auto' }}>
                  {projectRequestsApiData !== undefined && (
                    <DataTable
                      columns={columns as any}
                      data={projectRequestsApiData}
                      sortable
                      variant={{ rowDensity: 'default' }}
                      sortBy={{ id: 'createdOn', desc: true }}
                      style={{ overflowY: 'auto' }}
                    >
                      <DataTable.ExpandableRow>
                        {({ row }) => {
                          const requestId = row.projectRequestId;
                          // const projectRequestSentId = row.projectRequestSentId;
                          if (!fileInputRefs.current[requestId]) {
                            fileInputRefs.current[requestId] = [];
                          }
                          return (
                            <Flex flexDirection='column'>
                              <DataTable
                                columns={rowColumns as any}
                                data={row.assignedList}
                                variant={{ rowDensity: 'default', contained: false }}
                              >
                                <DataTable.ExpandableRow>
                                  {(assignedRow) => {
                                    // const assignedIndex = Number(assignedRow.row.index);
                                    return (
                                      <Flex flexDirection='column'>
                                        <DataTable
                                          columns={rowDocumentsColumns}
                                          data={assignedRow.row.documents}
                                          variant={{ rowDensity: 'default', contained: false }}
                                        >
                                          <DataTable.UserActions>
                                            <DataTable.RowActions>
                                              {(documentRow) => {
                                                const documentIndex = Number(documentRow.index);
                                                //In Local use the below line
                                                // const requestRow: any = documentRow.original.original;

                                                //In Dev use the below line
                                                const requestRow = documentRow.original;
                                                if (!fileInputRefs.current[requestId][documentIndex]) {
                                                  fileInputRefs.current[requestId][documentIndex] = null;
                                                }
                                                return (
                                                  <TableRowActions.Group>
                                                    <input
                                                      type='file'
                                                      ref={(el) =>
                                                        (fileInputRefs.current[requestId][documentIndex] = el)
                                                      }
                                                      style={{ display: 'none' }}
                                                      onChange={(event) =>
                                                        handleFileChange(
                                                          event,
                                                          requestId,
                                                          requestRow,
                                                          documentIndex,
                                                          projectRequests.current,
                                                        )
                                                      }
                                                    />
                                                    <Tooltip text={'Upload Document'}>
                                                      <Button
                                                        onClick={(requestRow: any) =>
                                                          handleUploadClick(requestId, requestRow as any, documentIndex)
                                                        }
                                                        disabled={requestRow.statusName === 'Approved'}
                                                      >
                                                        <Button.Prefix>
                                                          <UploadIcon />
                                                        </Button.Prefix>
                                                      </Button>
                                                    </Tooltip>
                                                    <Tooltip text={'Download Document'}>
                                                      <Button
                                                        onClick={() => {
                                                          // const item = documentRow.original;
                                                          downloadFileClick(documentRow.original);
                                                        }}
                                                        disabled={requestRow.statusName === 'Sent'}
                                                      >
                                                        <Button.Prefix>
                                                          <DownloadIcon />
                                                        </Button.Prefix>
                                                      </Button>
                                                    </Tooltip>
                                                  </TableRowActions.Group>
                                                );
                                              }}
                                            </DataTable.RowActions>
                                          </DataTable.UserActions>
                                        </DataTable>
                                      </Flex>
                                    );
                                  }}
                                </DataTable.ExpandableRow>
                              </DataTable>
                            </Flex>
                          );
                        }}
                      </DataTable.ExpandableRow>
                    </DataTable>
                  )}
                </Flex>
              </Tab>
              <Tab title='Notes'>
                <Flex flexDirection='column'>
                  {Notes(
                    newNotes,
                    setNewNotes,
                    AddNewNotesClick,
                    notes.current,
                    refetchProjectDetails,
                    addNewNotes,
                    setAddNewNotes,
                    truncateNotesText,
                  )}
                </Flex>
              </Tab>
            </Tabs>
            <Flex>&nbsp;</Flex>
          </Flex>
        </Flex>
      </Flex>
      {editDetailsVisible && editDetailsVisible.visibility && (
        <ProjectSheet
          closeDetails={(itemInfo?: ItemInfo) => {
            //reload the page as needed
            setEditDetailsVisible(itemInfo);
            refetchProjectDetails();
            // OnEditInfoSubmit(itemInfo);
          }}
          show
          itemInfo={editDetailsVisible}
        />
      )}
    </>
  );
};

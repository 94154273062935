import {
  FormField,
  Label,
  SelectV2,
  Sheet,
  TextArea,
  TextInput,
  TitleBar,
  Tooltip,
} from '@dynatrace/strato-components-preview';
import { Button, Flex } from '@dynatrace/strato-components';
import { Borders, Colors } from '@dynatrace/strato-design-tokens';
import { DeleteIcon } from '@dynatrace/strato-icons';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { isArray } from 'lodash';
import { RequestTemplate, RequestTemplateDetails } from '../../types/RequestTemplate';
import { ShowErrorNotification, ShowSuccessNotification } from '../../utils/Notifications';
import { TemplateService } from '../../services/TemplateService';
import { useAppInfo } from '../../contexts/AppContext';
import { LoadingStateComponent } from '../../components/LoadingStateComponent';
import { useDocumentTypeList, useReminderList } from '../../hooks/use-minerva-data';

interface RequestTemplateSheetProps {
  onTemplateClose: (tenant: number) => void;
  close: () => void;
  show: boolean;
  selectedTemplateId?: number | null;
  requestTemplate?: RequestTemplate | null;
}

interface ReminderDetails {
  remindersAutoId: number;
  remindersId: number;
  tenantId: number;
  details: string;
}

interface IDocument {
  label: string;
  documentTypeAutoId: number;
}

// Functional component definition
const RequestTemplateSheet: React.FC<RequestTemplateSheetProps> = ({
  close,
  show,
  selectedTemplateId,
  requestTemplate,
  onTemplateClose,
}) => {
  // const [documentTypes, setDocumentTypes] = useState<RequestTemplateDetails[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // const [reminderList, setReminderList] = useState<ReminderDetails[]>([]);
  const initialDocumentTypeAutoId = -1;
  const { tenantId } = useAppInfo();
  const documentTypes = useRef<RequestTemplateDetails[]>([] as RequestTemplateDetails[]);
  const reminderList = useRef<ReminderDetails[]>([] as ReminderDetails[]);
  // get document types list custom hook
  const {
    isLoading: documentTypesLoading,
    data: documentTypesData,
    error: documentTypesError,
    // refetch: refetchDocumentTypes,
  } = useDocumentTypeList(Number(tenantId));

  if (documentTypesError) {
    ShowErrorNotification('Error loading documnent types list', documentTypesError);
  }

  // get document types custom hook
  const {
    isLoading: reminderListLoading,
    data: reminderListData,
    error: reminderListError,
    // refetch: refetchReminderList,
  } = useReminderList(Number(tenantId));

  if (reminderListError) {
    ShowErrorNotification('Error loading reminders list', reminderListError);
  }

  const { handleSubmit, control, reset } = useForm<{
    requestTemplateName: string;
    templateDescription: string;
    reminder: number;
    document: Array<IDocument>;
  }>({
    mode: undefined,
    defaultValues: {
      document: [
        {
          label: '',
          documentTypeAutoId: -1,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'document',
  });

  // UseEffect to update form values when externalData changes
  useEffect(
    () => {
      if (requestTemplate) {
        updateRequestTemplate();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [requestTemplate],
  );

  const updateRequestTemplate = () => {
    if (requestTemplate) {
      // initialize values from the RequestTemplate
      reset({
        requestTemplateName: requestTemplate.requestTemplateName,
        templateDescription: requestTemplate.requestTemplateDescription,
        reminder: requestTemplate.remindersAutoId,
        document: requestTemplate.requestTemplateDetails.map((detail: any) => ({
          label: detail.label,
          documentTypeAutoId: detail.documentTypeAutoId,
        })),
      });
    } else {
    }
  };

  if (!documentTypesLoading && !reminderListLoading) {
    documentTypes.current = documentTypesData.documentTypes;

    reminderList.current = reminderListData;
    if (selectedTemplateId) {
      // setDocumentRequest();
    }
  }

  const handleAddMore = () => {
    append({
      label: '',
      documentTypeAutoId: -1,
    });
  };

  const saveDetails = async (values: any) => {
    const { requestTemplateName, templateDescription, reminder, document } = values;

    let formInit = {
      requestTemplateId: 0,
      requestTemplateName: '',
      requestTemplateDescription: '',
      remindersAutoId: '',
      requestTemplateDetails: [
        {
          documentTypeAutoId: 1,
          label: '',
        },
      ],
    };
    // let isValid = true;
    for (let i = 0; i < document.length; i++) {
      if (!document[i].label || document[i].label.trim() === '') {
        // isValid = false;
        ShowErrorNotification('Invalid Document Types');
        return;
      } else if (document[i].documentTypeAutoId === 1) {
        document[i].documentTypeAutoId = documentTypes.current[0].documentTypeAutoId;
      }
    }
    formInit.requestTemplateName = requestTemplateName;
    formInit.requestTemplateDescription = templateDescription;
    formInit.remindersAutoId = reminder;
    formInit.requestTemplateDetails = document;
    if (requestTemplate !== undefined && requestTemplate?.requestTemplateId !== undefined) {
      try {
        formInit.requestTemplateId = requestTemplate?.requestTemplateId;
        setLoading(true);
        const data: any = await TemplateService.updateRequestTemplates(formInit);
        if (data) {
          ShowSuccessNotification(values.requestTemplateName + ' successfully updated.');
          reset();
        }
      } catch (error) {
        ShowErrorNotification('Error updating template', error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        const data: any = await TemplateService.postRequestTemplates(formInit);
        if (data) {
          ShowSuccessNotification(values.requestTemplateName + ' successfully created.');
          reset({
            requestTemplateName: '',
            templateDescription: '',
            reminder: 0,
            document: [
              {
                label: '',
                documentTypeAutoId: initialDocumentTypeAutoId,
              },
            ],
          });
          onTemplateClose(tenantId);
        }
        close();
      } catch (error) {
        ShowErrorNotification('Error creating template', error);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <Sheet show={show} onDismiss={close}>
      <LoadingStateComponent loading={documentTypesLoading || reminderListLoading || loading} />
      <form onSubmit={handleSubmit(saveDetails)} onReset={() => reset()} noValidate>
        <TitleBar>
          <TitleBar.Title>
            {requestTemplate ? 'Edit ' + requestTemplate.requestTemplateName : 'Create Request Template'}
          </TitleBar.Title>
          <TitleBar.Subtitle>
            {requestTemplate ? 'Edit and manage tenant template' : 'Create a new tenant template'}
          </TitleBar.Subtitle>
          <TitleBar.Action style={{ display: 'flex', alignItems: 'center' }}>
            <Flex flexDirection='row' gap={8}>
              <Button
                width='75px'
                onClick={() => {
                  close();
                  // dismissDetails();
                }}
                variant='default'
              >
                Discard
              </Button>
              <Button width='60px' type='submit' variant='accent' color='primary' disabled={false}>
                Save
              </Button>
            </Flex>
          </TitleBar.Action>
        </TitleBar>

        <Flex flexDirection='column' margin={8} padding={0} gap={8}>
          <Flex flexDirection='column' width={'50%'} minWidth={325} gap={16}>
            <Controller
              name='requestTemplateName'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Template name is required.',
                },
                maxLength: {
                  value: 200,
                  message: 'Please enter a valid project name.',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormField required>
                  <Label>Request Template name</Label>
                  <TextInput
                    aria-label='Request Template name'
                    placeholder='Enter Template name'
                    controlState={{
                      state: error ? 'error' : 'valid',
                      hint: error?.message,
                    }}
                    {...field}
                  />
                </FormField>
              )}
            />
            <Controller
              name='templateDescription'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Description is required',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormField required>
                  <Label>Request Template Description</Label>
                  <TextArea
                    placeholder='Enter Template Description'
                    aria-label='Template Description'
                    width={'100%'}
                    controlState={{
                      state: error ? 'error' : 'valid',
                      hint: error?.message,
                    }}
                    {...field}
                  />
                </FormField>
              )}
            />
            <Controller
              name='reminder'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Please select a reminder.',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <FormField required>
                  <Label>Reminder</Label>
                  <Flex
                    onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                      if (event.key === 'Enter') {
                        event.preventDefault(); // Prevent form submission
                      }
                    }}
                  >
                    <SelectV2
                      aria-label='Reminder'
                      controlState={{
                        state: error ? 'error' : 'valid',
                        hint: isArray(error) ? error.join('\n') : error?.message,
                      }}
                      {...field}
                    >
                      <SelectV2.Trigger placeholder={'Reminder'} />

                      <SelectV2.Content style={{ maxWidth: '500px' }} width='max-content'>
                        {reminderList.current.map((option) => (
                          <SelectV2.Option key={option.remindersAutoId} value={option.remindersAutoId}>
                            {option.details}
                          </SelectV2.Option>
                        ))}
                        <SelectV2.Option
                          // key={index}
                          value={'none'}
                        >
                          none
                        </SelectV2.Option>
                      </SelectV2.Content>
                    </SelectV2>
                  </Flex>
                </FormField>
              )}
            />
            {fields.map((field, index) => (
              <Flex
                key={field.id}
                flexDirection='column'
                style={{
                  border: `1px solid ${Colors.Border.Neutral.Default}`,
                  borderRadius: Borders.Radius.Field.Default,
                  width: '100%',
                }}
                gap={8}
              >
                <Flex justifyContent='flex-end' style={{ marginRight: '8px', marginTop: '8px' }} gap={4}>
                  <Tooltip text={'Delete Document'}>
                    <Button
                      variant='emphasized'
                      aria-label='Remove'
                      style={{ textAlign: 'right', visibility: index > 0 ? 'visible' : 'hidden' }}
                      onClick={() => remove(index)}
                    >
                      <DeleteIcon />
                    </Button>
                  </Tooltip>
                </Flex>

                <Flex
                  flexDirection='column'
                  style={{ marginLeft: '24px', marginBottom: '16px', marginTop: '-24px' }}
                  gap={16}
                >
                  <Controller
                    name={`document.${index}.label`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Document Name is required',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Document Name</Label>
                        <TextInput
                          {...field}
                          value={field.value}
                          style={{ width: '80%' }}
                          placeholder='Enter Document name'
                          controlState={{
                            state: error ? 'error' : 'valid',
                            hint: error?.message || '',
                          }}
                        />
                      </FormField>
                    )}
                  />

                  <Controller
                    name={`document.${index}.documentTypeAutoId`}
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please choose an option.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <FormField required>
                        <Label>Document Type</Label>
                        <Flex
                          onKeyDown={(event: { key: string; preventDefault: () => void }) => {
                            if (event.key === 'Enter') {
                              event.preventDefault(); // Prevent form submission
                            }
                          }}
                        >
                          <SelectV2
                            {...field}
                            value={field.value}
                            controlState={{
                              state: error ? 'error' : 'valid',
                              hint: isArray(error) ? error.join('\n') : error?.message,
                            }}
                          >
                            <SelectV2.Trigger placeholder={'Document Type'} />
                            <SelectV2.Filter></SelectV2.Filter>
                            <SelectV2.Content width={'auto'}>
                              {documentTypes.current.map((option) => (
                                <SelectV2.Option key={option.documentTypeAutoId} value={option.documentTypeAutoId}>
                                  {option.documentTypeName}
                                </SelectV2.Option>
                              ))}
                            </SelectV2.Content>
                          </SelectV2>
                        </Flex>
                      </FormField>
                    )}
                  />
                </Flex>
              </Flex>
            ))}
            <Flex flexDirection='column' gap={8}>
              <Button style={{ color: Colors.Text.Primary.Default }} onClick={handleAddMore}>
                Add more
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </form>
      <Flex></Flex>
    </Sheet>
  );
};

export default RequestTemplateSheet;
